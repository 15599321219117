@import "../../variables";
@import "../../mixins";

.delete-btn {
    @extend %delete-btn;
    &:hover, 
    &:active {
        text-decoration: none !important;
    }
}

.top-menu {
    @extend %top-menu;
}

.bottom-bar {
    .add-button {
        svg {
            pointer-events: none;
            width:30px;
            height:30px;
            fill: var(--gray);
        }
    }
}

.tips {
    &.container-fluid {
        padding-right: 10px;
        padding-left: 10px;
    }
    .row {
        margin-left: -5px;
        margin-right: -5px;
    }
    .col-sm {
        padding-left: 5px;
        padding-right: 5px;
    }

    .tip {
        &:focus, &:hover {
            text-decoration: none;
            // background-color: $entity_hover_color;
            color:$text-color;
        }
    }
}
