// Editor.
$font-size-editor-mobile: 17px;
$font-size-editor-desktop: 19px;
$font-size-editor-h1-mobile: 33px;
$font-size-editor-h1-desktop: 40px;
$font-size-editor-h3-mobile: 30px;
$font-size-editor-h3-desktop: 34px;
$font-size-editor-h4-mobile: 24px;
$font-size-editor-h4-desktop: 26px;
$line-height-editor-mobile: 1.6; //1.58;
$line-height-editor-desktop: 1.68; //1.58;
$letter-spacing-editor-mobile: -0.004em;
$letter-spacing-editor-desktop: -0.003em;
$margin-bottom-editor-p-mobile: 20px;
$margin-bottom-editor-p-desktop: 30px;

$screen-sm-min: 768px !default;
$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif !default;

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Story and Page Grids.
$item-xs-width: 250px; // 0,666666667
$item-xs-height: 375px;
$item-sm-width: 300px; // 0,666666667
$item-sm-height: 450px;
$item-lg-width: 400px; // 0,666666667
$item-lg-height: 600px;

//$player-next-icon-color: rgba(#000000, .5) !default;
//$player-next-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='16' viewBox='0 0 12 16'%3E%3Cpath fill='#{$player-next-icon-color}' fill-rule='evenodd' d='M7.48 8l3.75 3.75-1.48 1.48L6 9.48l-3.75 3.75-1.48-1.48L4.52 8 .77 4.25l1.48-1.48L6 6.52l3.75-3.75 1.48 1.48L7.48 8z'/%3E%3C/svg%3E"), "#", "%23") !default;

// Used for both stories and pages.
$card-box-shadow: 0 0 4px 0 hsla(0,0%,80%,.7);

/// ---- LVDP ---- ///
$gray-middle: #cccccc;
$gray-light: #cccccc;
$font-size-small: 14px;
$font-size-large: 18px;
$entity_hover_color: #ecf7fd;
$text-color: #636b6f;

$lvdp-font-epicride: 'EpicRide', sans-serif;
$lvdp-font-foco-bold: 'Foco Bold', serif;
$lvdp-font-foco: 'Foco', serif;
$lvdp-font-lora: 'Lora', serif;

// Frontpage.
$lvdp-frontpage-title-color: #d3b6a0;
$lvdp-frontpage-title-font-size: 110px;
$lvdp-frontpage-text-font-size: 1.15em;
$lvdp-frontpage-separator-color: #eddfd4;
$lvdp-frontpage-highlighted-section-bg-color: #f7f2ee;
$lvdp-frontpage-subtitle-text-color: #878787;

// The main layout.
$lvdp-footer-header-bg-color: #f7f2ee;
$lvdp-footer-link-color: #878787;

$lvdp-menu-bg-color: #f6f2ef;
$lvdp-menu-link-color: $lvdp-footer-link-color !important;
$lvdp-menu-link-color-active: #c5b8a8 !important;
$lvdp-menu-link-font-size: 12px;
$lvdp-menu-link-font-family: $lvdp-font-foco;
$lvdp-menu-link-letter-spacing: 0.2em;
$lvdp-menu-link-line-height: 23px !important;
$lvdp-frontpage-title-section-font-family: $lvdp-menu-link-font-family;
/// ---- LVDP ---- ///

