@import "../variables";
@import "../mixins";

.delete-btn {
    &:hover, 
    &:active {
        text-decoration: none !important;
    }
}

.top-menu {
    @extend %top-menu;
}

.bottom-bar {
    .add-button {
        svg {
            pointer-events: none;
            width:30px;
            height:30px;
            fill: var(--gray);
        }
    }
}

.stories {
    margin-left:auto;
    margin-right:auto;
    background-color: #e5e5e5;
    .story {
        box-shadow: $card-box-shadow;
        text-align: center;
        width: $item-xs-width;
        height: $item-xs-height;
        margin:15px;
        background-color: var(--white);
        background-repeat: no-repeat;
        background-size: cover;
        word-wrap:break-word;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: var(--white);
        text-decoration: none;
        z-index:1;
        position: relative;

        @extend %title-and-subtitle;

        // If it's a story list page and we don't use Page component for it,
        // We need to darken backgrounds additionally.
        &.COVER.has-bg {
            &:after {
                background-color: rgba(0, 0, 0, 0.07);
                position: absolute;
                top:0;
                bottom:0;
                left:0;
                right:0;
                content: '';
            }

            &.CLASSIC:after {
                background-color: rgba(0, 0, 0, 0.15);
            }
            
            &.NICO {
                &:after {
                    background-color: rgba(0, 0, 0, 0.25);
                }
            }
            &.LAMODE {
                &:after {
                    background-color: rgba(0, 0, 0, 0.2);
                }
            }
        }

        .info {
            overflow-wrap: break-word;
            background-color: rgba(#222222, 0.5);
            color:white;
            z-index: 1;
            
            .details {
              .location {
                padding-right: 2px;
                text-align: left;
              }
              .date {
                text-align: right;
                padding-left: 2px;
                text-transform: capitalize;
              }
            }
        }
        .delete-btn {
            // @extend %neutralize-button;
            // background-color: rgba(0, 0, 0, 0.6);
            // color: var(--white);
            @extend %delete-btn;
        }
    }
}

@media (min-width: $breakpoint-sm) {
    .stories {
        .story {
            width: $item-sm-width;
            height: $item-sm-height;
        }
    }
}

@media (min-width: $breakpoint-lg) {
    .stories {
        .story {
            width: $item-lg-width;
            height: $item-lg-height;
        }
    }
}
