@import "../variables";
@import "../mixins";

.top-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left:0;
    padding-right:0;
    padding-top: .25rem;
    text-align: center;
    @extend %top-menu;
}
