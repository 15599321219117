%top-menu {
    background-color: #f8f8f8;
    button, 
    a, 
    span {
        @extend %neutralize-button;
        padding: 8px 14px;
        text-decoration: none;
        color: buttontext;

        &:hover, &:focus {
            outline: none;
            appearance: none;
            text-decoration: none;
            color: buttontext;
        }
        &.active {
            color: var(--primary);
        }
    }
    .separator {
        width:2px;        
        .bg {
            background-color: #ddd;
            height:20px;
        }
    }
}

%neutralize-button {
    border:none;
    background-color: transparent;
    outline: none;
    appearance: none !important;
    cursor: pointer;
    &:hover, &:focus {
        outline: none;
    }
}

%neutralize-input {
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    border-radius: unset;
}

%player-button {
    @extend %neutralize-button;
    width:34px;
    height: 100%;
    position: absolute;
    top:0;
    z-index:11;
    display: block;
    padding:5px;
    svg {
        width: 26px;
        height: 26px;
        pointer-events: none;
        fill: var(--dark);
    }
}

%delete-btn {
    @extend %neutralize-button;
    background-color: rgba(0, 0, 0, 0.6);
    color: var(--white);
    position: absolute;
    top:0;
    right:0;
    z-index:3;
    padding: 0.75rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    svg {
        margin:0 auto;
        pointer-events: none;
        width: 16px;
        height: 16px;
        stroke: var(--white);
        fill: var(--white);
    }
}

%page-title {
    text-align: center;
    color: #ffffff;
    background-color: transparent;
    width: 100%;
    margin:0 auto;
    text-align: center;
    div {
        margin-left:15px;
        margin-right:15px;
        font-size: 2rem;
    }
    word-wrap: break-word;
}

%page-subtitle {
    @extend %page-title;

    div {
        font-size: 1rem;
    }
}

@mixin cover_layout($layout_name: CLASSIC) {
    .img-container:after {
        background-color: rgba(0, 0, 0, 0.07);
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        content: '';
    }
    @if $layout_name == CLASSIC {
        .img-container:after {
            background-color: rgba(0, 0, 0, 0.15);
        }
        .title-and-subtitle {
            bottom: 70px;
            .title {
                margin-bottom: 16px;

                div {
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 3em;
                    line-height: 1.17188;
                    letter-spacing: 0.03em;
                }
            }
            .subtitle {
                div {
                    font-style: italic;
                    font-family: 'Libre Baskerville', serif;
                    font-weight: 400;
                    font-size: 1.33333em;
                    line-height: 1.17188;
                    letter-spacing: 0em;
                }
            }
        }
    } 
    @else if $layout_name == NICO {
        .img-container:after {
            background-color: rgba(0, 0, 0, 0.25);
        }
        .title-and-subtitle {
            top: 0;
            bottom: 32px;
            left: 0;
            right: 0;

            display: flex;
            justify-content: center;
            flex-direction: column;

            .title {
                div {
                    font-size: 4.16667em;
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 200;
                    line-height: 1.17188;
                    letter-spacing: 0em;
                }
            }
            .subtitle {
                margin-top:7px;
                div {
                    font-size: 1.33333em;
                    font-family: 'Lora', serif;
                    line-height: 1.17188;
                    letter-spacing: 0.03em;
                }
            }
        }
    } 
    @else if $layout_name == WANDER {
        .title-and-subtitle {
            top:0;
            bottom:unset;
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            height:100%;
            .title {
                margin-top: 30%;
                width: 80%;
                background-image: url(/img/CoverPageLayouts/misc/wander/top-border.png);
                background-position: top center;
                background-repeat: no-repeat;
                background-size: contain;
                border-bottom: 1px solid var(--white);
                div {
                    padding-top: 24px;
                    font-size: 3.33333em;
                    font-family: 'Roboto Slab', serif;
                    line-height: 1.17188;
                    letter-spacing: 0.05em;
                }
                padding-bottom: 17px;
            }
            .subtitle {
                margin-bottom: 60px;

                div {
                    font-size: 1.5em;
                    font-family: 'Roboto Condensed', sans-serif;
                    font-weight: 300;
                    line-height: 1.17188;
                    letter-spacing: 0em;
                }
            }
        }
    }
    @else if $layout_name == HYDE {
        .title-and-subtitle {
            background-image: url(/img/CoverPageLayouts/misc/hyde/frame.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        
            width: unset;
                
            top: 22px;
            bottom: 54px;
            left: 32px;
            right: 32px;

            .title {
                margin-top: 18%;
                div {
                    font-family: 'Playfair Display', serif;
                    font-size: 3.33333em;
                    line-height: 1.17188;
                    letter-spacing: 0em;
                }
            }
            .subtitle {
                position: absolute;
                bottom: 12%;

                div {
                    font-family: 'Playfair Display', serif;
                    font-style: italic;
                    font-size: 1.33333em;
                    line-height: 1.17188;
                    letter-spacing: 0em;
                }
            }
        }
    }
    @else if $layout_name == SURFACE {
        .title-and-subtitle {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            .title {
                background-color: var(--white);
                margin-top:20%;
                width: 70%;
                max-width: 200px;
                padding: 14px 12px;
                div {
                    color: #000000;
                    margin:0;
                    font-family: 'Raleway', sans-serif;
                    font-weight: bold;
                    font-size: 1.83333em;
                    line-height: 1.44141;
                    letter-spacing: 0.35em;
                }
            }
            .subtitle {
                position: absolute;
                bottom: 12%;
                div {
                    font-family: 'Raleway', sans-serif;
                    font-weight: 500;
                    font-size: 1.16667em;
                    line-height: 1.44141;
                    letter-spacing: 0.25em;
                }
            }
        }
    }
    @else if $layout_name == LAMODE {
        .img-container:after {
            background-color: rgba(0, 0, 0, 0.2);
        }

        .title-and-subtitle {
            top: 0;
            bottom: 32px;
            left: 0;
            right: 0;

            display: flex;
            justify-content: center;
            flex-direction: column;

            .title {
                div {
                    font-family: 'Prata', serif;
                    font-size: 3.33333em;
                    line-height: 1.17188;
                    letter-spacing: 0.05em;
                }
            }
            .subtitle {
                margin-top: 9px;
                div {
                    padding-top:24px;
                    border-top: 3px solid #ffffff;
                    max-width: 205px;
                    margin: 0 auto;

                    font-family: 'Open Sans', sans-serif;
                    font-weight: 300;
                    font-size: 1.5em;
                    line-height: 1.17188;
                    letter-spacing: 0em;
                }
            }
        }
    }
    @else {
        @error "Unknown direction #{$layout_name}.";
    }
}

%title-and-subtitle {
    .title-and-subtitle {
        z-index:1;
        position: absolute;
        bottom: 0;
        width:100%;
        .title {
            @extend %page-title;
        }
        .subtitle {
            @extend %page-subtitle;
        }
    }

    &.CLASSIC {
        @include cover_layout(CLASSIC);
    }
    &.NICO {
        @include cover_layout(NICO);
    }
    &.WANDER {
        @include cover_layout(WANDER);
    }
    &.HYDE {
        @include cover_layout(HYDE);
    }
    &.SURFACE {
        @include cover_layout(SURFACE);
    }
    &.LAMODE {
        @include cover_layout(LAMODE);
    }
}


%content-teaser {
    display: block;
    padding:0;
    border: 1px solid $lvdp-frontpage-separator-color;
    color:$text-color;
    background-color: white;
    margin-bottom: 10px;
    font-size: $font-size-small;
    &:focus, &:hover {
      text-decoration: none;
      background-color: #fefcfa;
      color:$text-color;
    }
  
    margin-bottom: 10px;
    width: 100%;

    position: relative;

    .controls {
        position: absolute;
        right:0;
        top:0;
    }
  
    .content {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-start;
  
      .row-first {
        width: 100%;
  
        .photo-container {
          background-color: #cccccc;
          position: relative;
          min-height: 50px;
          .photo {
            border:none;
            img {
            //   @include img-responsive;
              width:100%;
            }
          }
        }
      }
      .row-second {
        width:100%;
        padding: 8px 14px 8px 10px;
        text-align: left;
        justify-content: flex-end;
  
        .info {
          font-family: $lvdp-font-foco;
          font-size: 12px;
          color: $lvdp-frontpage-subtitle-text-color;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          margin-bottom:8px;
          .delimiter {
            padding: 0 4px;
          }
        }
  
        .title {
          font-family: $lvdp-font-lora;
          font-size: 18px;
          color: #2c2c2c;
          margin-bottom: 6px;
        }

        .body {
          font-family: $lvdp-font-foco;
          font-size: 16px;
          color: $lvdp-frontpage-subtitle-text-color;
        }
      }
    }
}

@mixin frontpage-cta-button($text-color, $bg-color) {
    font-family: $lvdp-font-foco-bold;
    font-size: 14px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: $text-color;
    width: auto;
    background-color: $bg-color;
    border: none;
    padding: 12px 40px;
    margin-top: 40px;
    // @include border-radius(2px);
    border-radius: 2px;
    svg {
      fill: $text-color;
    }
    &:hover, &:active, &:focus {
      box-shadow: none;
      background-color: darken($bg-color, 10%) !important;
      color: $text-color !important;
    }
    .label {
      line-height: 24px;
      display: inline-block;
      padding-left: 10px;
      vertical-align: middle;
    }
}

@mixin transition($transition...) {
    -webkit-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}
