@import "../variables";
@import "../editor";
@import "../mixins";
@import "../fonts";

.page {
    height: 100%;
    min-height: 100%;
    
    .layout-wrapper {
        height: 100%;
        min-height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;

        .delete-btn {
            @extend %delete-btn;
        }

        .img-container {
            background-color: #cccccc;
            position: relative;
            touch-action: none;
            cursor: pointer;
            background-repeat: no-repeat;
            background-size: cover;
            .edit-button {
                text-align: center;
                width: 100%;
                position: absolute;
                top:43%;
                top:calc(50% - 20px - 6px);
                left:0;
                svg {
                    pointer-events: none;
                    width:40px;
                    height:40px;
                    margin-left:4px;
                }
            }
        }
        
        .text-container {
            @extend %editor-typography;
            word-break: break-word;
            display: flex;
            flex-direction: column;
            justify-content: center;

            *:nth-last-child(1) {
                margin-bottom:0;
            }

            .responsive-video {
                min-height: 162px;
            }

            &.has-scroll {
                justify-content: flex-start;
            }
            
            p {
                text-align: left;
            }
        }
        
        &.IMAGE, &.FRAMED_IMAGE, &.COVER {
            .img-container {
                height: 100%;
                min-height: 100%;
                .image {
                    height: 100%;
                    min-height: 100%;
                }
            }
        }
        &.FRAMED_IMAGE {
            padding:15px;
        }
        &.TWO_IMAGES {
            .img-container {
                height: 50%;
                min-height: $item-xs-height / 2; // 375 / 2
            }
        }
        &.TWO_FRAMED_IMAGES {
            .img-container {
                width: calc(100% - 20px); // 250 - 20 (two borders)
                height: calc(50% - 15px);
                min-height: $item-xs-height / 2 - 15px; // 375 / 2 - 15 (two borders)
                box-sizing: border-box;
                margin:10px 10px 5px 10px;
                &:nth-last-child(1) {
                    margin-top:5px;
                }
            }
        }

        &.IMAGE_WITH_TITLE, &.QUOTE {
            .img-container {
                height: 100%;
                min-height: 100%;
                .edit-button {
                    top: 50px;
                }
            }
            .text-container {
                position: absolute;
                top: 38%;
                padding:1.8rem;
                width:100%;
                height: auto; 
                min-height: 50px;
                overflow-y: auto;
                word-wrap: break-word;
                color: var(--light);
                text-shadow: 0px 0px 5px var(--dark);
                border-top: 2px dashed transparent;
                border-bottom: 2px dashed transparent;
                
                * {
                    margin-bottom: inherit;
                }

                &.edit {
                    // background-color: transparent;
                    // border-top: none;
                    // border-bottom: none;
                    // background-color: rgba(150,150,150, 0.3);
                    border-top: 2px dashed var(--light);
                    border-bottom: 2px dashed var(--light);
                }
            }
        }
        &.QUOTE {
            border:none;

            .img-container {
                .edit-button {
                    top:unset;
                    bottom:20px;
                }
                position: relative;
                &::before {
                    background-color: #000000;
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    opacity: 0.3;
                }
            }
            .text-container {
                z-index:2;
                min-height: 100%;
                top:0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                blockquote {
                    text-align: center;
                    font-size: 2rem;
                    line-height: 1.61798;
                    text-shadow: none;
                    font-family: 'Prata', serif;
                    border-left: none;
                    em {
                        text-align: center;
                        font-size: 1rem;
                        line-height: 1.6438;
                        letter-spacing: 0.2rem;
                        font-style: normal;
                        text-shadow: none;
                        font-family: 'Open Sans', sans-serif;
                        font-weight: 300;
                    }
                }
                &.edit {
                    top: 10%;
                    border:none;
                    min-height: unset;
                    max-height: 270px;
                    height: 270px;
                    overflow-y: hidden;

                    blockquote {
                        font-size: 24px;
                        em {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        &.IMAGE_AND_TEXT, &.FRAMED_IMAGE_AND_TEXT {
            .img-container {
                // height: $item-xs-width;
                height: 60%;
                min-height: $item-xs-width;
            }
            .text-container {
                padding:1.8rem;
                // height: $item-xs-height - $item-xs-width; // 345 - 230;
                height: 40%;
                min-height: $item-xs-height - $item-xs-width;
                overflow-y: auto;
                word-wrap: break-word;
            }
        }
        &.FRAMED_IMAGE_AND_TEXT {
            padding:30px;
            .img-container {
                min-height:190px !important;
                background-position: center center;
                position: relative;
                border: 5px solid #eeeeee;
            }
        }
        &.TEXT {
            height: 100%;
            min-height: 100%;
            .text-container {
                padding:1.8rem;
                height: 100%;
                min-height: 100%;
                overflow-y: auto;
                word-wrap: break-word;
            }
        }
        &.COVER {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .img-container {
                .edit-button {
                    top: 20px;
                    text-align: right;
                    padding-right:20px;
                }
            }

            @extend %title-and-subtitle;

            // If it's a story list page and we don't use Page component for it,
            // We need to darken backgrounds additionally.
            &.COVER.has-bg {
                &:after {
                    background-color: rgba(0, 0, 0, 0.07);
                    position: absolute;
                    top:0;
                    bottom:0;
                    left:0;
                    right:0;
                    content: '';
                }

                &.CLASSIC:after {
                    background-color: rgba(0, 0, 0, 0.15);
                }
                
                &.NICO {
                    &:after {
                        background-color: rgba(0, 0, 0, 0.25);
                    }
                }
                &.LAMODE {
                    &:after {
                        background-color: rgba(0, 0, 0, 0.2);
                    }
                }
            }

            .info {
                overflow-wrap: break-word;
                background-color: rgba(34, 34, 34, 0.5);
                color: white;
                position: absolute;
                width: 100%;
                bottom: 0;
                z-index:1;
                
                .details {
                    .location {
                        padding-right: 2px;
                        text-align: left;
                    }
                    .date {
                        text-align: right;
                        padding-left: 2px;
                        text-transform: capitalize;
                    }
                }
            }

        }
    }
}

@media (min-width: $breakpoint-sm) {
    .page {
        .layout-wrapper {
            &.IMAGE_WITH_TITLE {
                .text-container {
                    top: 40%;
                }
            }
            &.IMAGE_AND_TEXT {
                .img-container {
                    height: $item-sm-width;
                    min-height: $item-sm-width;
                }
                .text-container {
                    height: $item-sm-height - $item-sm-width; // 450 - 300
                    min-height: $item-sm-height - $item-sm-width;
                }
            }
            &.FRAMED_IMAGE_AND_TEXT {
                min-height:240px;
            }
            &.QUOTE {
                .text-container {
                    blockquote {
                        font-size: 2.5rem;
                        em {
                            font-size: 1.35rem;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $breakpoint-lg) {
    .page {
        .layout-wrapper {
            &.IMAGE_WITH_TITLE {
                .text-container {
                    top: 43%;
                }
            }
            &.IMAGE_AND_TEXT {
                .img-container {
                    height: $item-lg-width;
                    min-height: $item-lg-width;
                }
                .text-container {
                    height: $item-lg-height - $item-lg-width; // 600 - 400
                    min-height: $item-lg-height - $item-lg-width;
                }
            }
        }
    }
}
