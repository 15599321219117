@import "../variables";
@import "../editor";
@import "../mixins";

.editor-container {
  background-color: var(--white);
}

.page-editor {
  &.quill {
    @extend %editor;
    word-break: break-word;
    border-bottom: none;
  }
}

.ql-toolbar.ql-snow {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  right: 0;
  background-color: var(--dark);
  border: none;
  color: var(--light);
  opacity: 1;
  z-index: 1;

  text-align: center;

  .ql-formats {
    // margin-right: 0;
  }
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: 1px solid #ccc;
}

.ql-editor {
  padding: 0.7rem !important;
}

.ql-toolbar.ql-snow {
  .ql-header {
    color: var(--light);
    &.ql-active,
    &:hover,
    &:focus {
      color: var(--blue);
    }
  }
}

.ql-snow .ql-stroke {
  stroke: var(--light);
}
.ql-snow .ql-fill {
  fill: var(--light);
}
.ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill {
  fill: var(--light);
} 
.ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke {
  stroke: var(--light);
} 
.ql-snow.ql-toolbar button:hover:not(.ql-active) {
  color: var(--light);
}
