@import "../variables";
@import "../mixins";

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.add-button {
    @extend %neutralize-button;
    margin: 1rem auto;
    svg {
        pointer-events: none;
        width:44px;
        height:44px;
        fill: var(--gray);
    }
    &:hover {
        outline: none;
    }
}

.image-container {
    position: relative;
    touch-action: none;

    .edit-button {
        text-align: center;
        width: 100%;
        position: absolute;
        top:43%;
        top:calc(50% - 30px - 6px);
        left:0;
        cursor: grab;
        svg {
            pointer-events: none;
            width:60px;
            height:60px;
            fill: rgba(150, 150, 150, 0.6);
        }
    }
    .my-spinner {
        text-align: center;
        position: absolute;
        top:45%;
        top:calc(50% - 16px - 6px);
        left:45%;
        margin:0 auto;
    }
}
