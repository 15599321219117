@import "../../variables";
@import "../../editor";
@import "../../mixins";

.tip-editor {

    &.quill {
        @extend %editor;
        
        .ql-container .ql-editor {
            font-family: 'Open Sans', sans-serif;
            font-size: 20px;
            line-height: 1.6;
            color: #000000;
        }

        margin-bottom:2em;
        padding-bottom: 2.5em;
        border-bottom: none !important;
    }
}
