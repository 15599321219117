@import "../variables";
@import "../mixins";

.pages {
  position: absolute;
  top: 40px;
  width: 100%;
  height: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  margin-top: unset;
}

@media (min-width: $breakpoint-sm) {
  .pages {
    position: relative;
    top: 0;
    // margin-top: 40px;
    margin-top: 25px;
  }
}
