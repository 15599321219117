@import "../variables";
@import "../mixins";

.prev-btn {
  @extend %player-button;
  left:-34px;
}
.next-btn {
  @extend %player-button;
  right:-34px;
}

.container {
  height: 100%; 
  min-height: 100%; 
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  position: relative;
  touch-action: none;
}

// :global {
//   .swiper-container {
//     height: 100%;
//     min-height: 100%;
//   }
// }

.player {
  // height: 600px;
  // min-height: 600px;
  // height: calc(100% - 48px - 40px);
  // min-height: calc(100% - 48px - 40px);
  width:auto;
  margin: 0 auto;
  position: relative;
  touch-action: none;
}

.two-pages {
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100%;
  margin: 0 auto;
  touch-action: none;
}

.page {
  color: #000000;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 100%;
  min-height: 100%;
  text-align: center;
  touch-action: none;
  box-sizing: content-box;
  border-right: none;
}

.bottom-panel {
  background-color: #000000;
  color: #ffffff;
  height: 40px;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.vertical-rhythm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div {
    padding: 5px 10px;
  }
}

@media (min-width: $breakpoint-sm) {
  .prev-btn {
    display: block;
    left:-34px;
  }
  .next-btn {
    display: block;
    right:-34px;
  }

  .container {
    height: 600px;
    min-height: 600px;
  }
}
