@import "../../variables";
@import "../../editor";
@import "../../mixins";

.quill {
    @extend %editor;
    
    margin-bottom:2em;
    padding-bottom: 2.5em;
    border-bottom: none !important;
}
