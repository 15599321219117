@import url(https://fonts.googleapis.com/css?family=Lato|Rock+Salt|Open+Sans:300|Prata|Libre+Baskerville:400i|Montserrat:200,400|Lora|Roboto+Condensed:300|Roboto+Slab:300|Playfair+Display:400,400i|Raleway:500,700);
@import url(https://fonts.googleapis.com/css?family=Lora&display=swap);
.FormTopMenu_top-menu__1eofZ {
  background-color: #f8f8f8; }
  .FormTopMenu_top-menu__1eofZ button,
  .FormTopMenu_top-menu__1eofZ a,
  .FormTopMenu_top-menu__1eofZ span {
    padding: 8px 14px;
    text-decoration: none;
    color: buttontext; }
    .FormTopMenu_top-menu__1eofZ button:hover, .FormTopMenu_top-menu__1eofZ button:focus,
    .FormTopMenu_top-menu__1eofZ a:hover,
    .FormTopMenu_top-menu__1eofZ a:focus,
    .FormTopMenu_top-menu__1eofZ span:hover,
    .FormTopMenu_top-menu__1eofZ span:focus {
      outline: none;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      text-decoration: none;
      color: buttontext; }
    .FormTopMenu_top-menu__1eofZ button.FormTopMenu_active__3XA2h,
    .FormTopMenu_top-menu__1eofZ a.FormTopMenu_active__3XA2h,
    .FormTopMenu_top-menu__1eofZ span.FormTopMenu_active__3XA2h {
      color: var(--primary); }
  .FormTopMenu_top-menu__1eofZ .FormTopMenu_separator__3xRKZ {
    width: 2px; }
    .FormTopMenu_top-menu__1eofZ .FormTopMenu_separator__3xRKZ .FormTopMenu_bg__cmNAK {
      background-color: #ddd;
      height: 20px; }

.FormTopMenu_top-menu__1eofZ button,
.FormTopMenu_top-menu__1eofZ a,
.FormTopMenu_top-menu__1eofZ span {
  border: none;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  cursor: pointer; }
  .FormTopMenu_top-menu__1eofZ button:hover,
  .FormTopMenu_top-menu__1eofZ a:hover,
  .FormTopMenu_top-menu__1eofZ span:hover, .FormTopMenu_top-menu__1eofZ button:focus,
  .FormTopMenu_top-menu__1eofZ a:focus,
  .FormTopMenu_top-menu__1eofZ span:focus {
    outline: none; }

.FormTopMenu_top-menu__1eofZ {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;
  padding-top: .25rem;
  text-align: center; }

.layout {
  height: 100%;
  min-height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  background-color: #e5e5e5; }
  .layout.light {
    background-color: var(--white); }

.quill .ql-container {
  font-size: 17px;
  line-height: 1.6;
  letter-spacing: -0.004em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  /*
  @media (min-width: $screen-sm-min) {
    font-size: $font-size-editor-desktop;
    line-height: $line-height-editor-desktop;
    letter-spacing: $letter-spacing-editor-desktop;

    ol,
    ul {
      padding-left: 2em;
    }

    blockquote {
      font-size: $font-size-editor-desktop + 1px;
      margin-bottom: $margin-bottom-editor-p-desktop;
    }

    p,
    ul {
      margin-bottom: $margin-bottom-editor-p-desktop;
    }

    h1 {
      font-size: $font-size-editor-h1-desktop;
    }
    h3 {
      font-size: $font-size-editor-h3-desktop;
    }
    h4 {
      font-size: $font-size-editor-h4-desktop;
    }
  }
  */ }
  .quill .ql-container h1,
  .quill .ql-container .h1,
  .quill .ql-container h2,
  .quill .ql-container .h2,
  .quill .ql-container h3,
  .quill .ql-container .h3 {
    margin-top: 0;
    margin-bottom: 30px; }
  .quill .ql-container h1,
  .quill .ql-container h2,
  .quill .ql-container h3,
  .quill .ql-container h4,
  .quill .ql-container h5,
  .quill .ql-container h6,
  .quill .ql-container .h1,
  .quill .ql-container .h2,
  .quill .ql-container .h3,
  .quill .ql-container .h4,
  .quill .ql-container .h5,
  .quill .ql-container .h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit; }
  .quill .ql-container ol,
  .quill .ql-container ul {
    padding-left: 1.5em; }
    .quill .ql-container ol li,
    .quill .ql-container ul li {
      padding-left: 0.2em;
      text-align: left; }
  .quill .ql-container blockquote {
    font-size: 18px;
    margin-bottom: 19px;
    padding: 12.5px 25px;
    border-left: 5px solid #eeeeee; }
  .quill .ql-container p,
  .quill .ql-container ul,
  .quill .ql-container .responsive-video {
    margin-bottom: 20px; }
  .quill .ql-container h1 {
    font-size: 33px; }
  
  .quill .ql-container h3 {
    font-size: 30px; }
  
  .quill .ql-container h4 {
    font-size: 24px; }
  .quill .ql-container .ql-font-opensans {
    font-family: 'Open Sans', sans-serif; }
  .quill .ql-container .ql-font-roboto {
    font-family: 'Roboto', sans-serif; }
  .quill .ql-container .ql-font-raleway {
    font-family: 'Raleway', sans-serif; }
  .quill .ql-container img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block; }
  .quill .ql-container a {
    color: #06c !important; }
    .quill .ql-container a:hover, .quill .ql-container a:focus {
      color: #0056b3 !important; }

.quill {
  padding: 10px; }
  .quill .ql-container .ql-video {
    margin-bottom: 20px; }
  .quill .ql-container img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block; }
  .quill:focus {
    outline: 0 !important;
    -webkit-appearance: none; }
  .quill::after {
    font-style: normal; }
  .quill .ql-toolbar .ql-header {
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    color: #ccc; }
    .quill .ql-toolbar .ql-header.ql-active, .quill .ql-toolbar .ql-header:hover, .quill .ql-toolbar .ql-header:focus {
      color: white; }
  .quill .ql-editor {
    padding: 0;
    line-height: inherit; }
    .quill .ql-editor :last-child {
      margin-bottom: 0; }
  .quill input.ql-image[type=file] {
    display: none; }
  .quill .ql-snow .ql-picker.ql-color .ql-picker-item:nth-child(1) {
    border: 1px solid lightblue; }
  .quill .ql-toolbar.ql-snow .ql-size.ql-picker.ql-expanded .ql-picker-options {
    top: -535px; }
  .quill .ql-snow .ql-picker.ql-size {
    width: 60px; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label::before {
      content: '(d)'; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before {
      content: '-5'; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="13px"]::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="13px"]::before {
      content: '-4'; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before {
      content: '-3'; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="15px"]::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="15px"]::before {
      content: '-2'; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before {
      content: '-1'; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="17px"]::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="17px"]::before {
      content: '0'; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before {
      content: '+1'; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="19px"]::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="19px"]::before {
      content: '+2'; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before {
      content: '+3'; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="21px"]::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="21px"]::before {
      content: '+4'; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before {
      content: '+5'; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="23px"]::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="23px"]::before {
      content: '+6'; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before {
      content: '+7'; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="25px"]::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="25px"]::before {
      content: '+8'; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before {
      content: '+9'; }
    .quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="27px"]::before, .quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="27px"]::before {
      content: '+10'; }
  .quill .ql-toolbar.ql-snow .ql-font.ql-picker.ql-expanded .ql-picker-options {
    top: -132px; }
  .quill .ql-snow .ql-picker.ql-font .ql-picker-item::before, .quill .ql-snow .ql-picker.ql-font .ql-picker-label::before {
    content: '(default)'; }
  .quill .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="opensans"]::before, .quill .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="opensans"]::before {
    content: 'Open Sans'; }
  .quill .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="roboto"]::before, .quill .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="roboto"]::before {
    content: 'Roboto'; }
  .quill .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="raleway"]::before, .quill .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="raleway"]::before {
    content: 'Raleway'; }
  .quill .ql-toolbar.ql-snow .ql-lineheight.ql-picker.ql-expanded .ql-picker-options {
    top: -380px; }
  .quill .ql-snow .ql-picker.ql-lineheight {
    width: 60px; }
    .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item::before, .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label::before {
      content: '(d)'; }
    .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1']::before, .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1']::before {
      content: '1.0'; }
    .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.2']::before, .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.2']::before {
      content: '1.2'; }
    .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.5']::before, .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.5']::before {
      content: '1.5'; }
    .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.6']::before, .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.6']::before {
      content: '1.6'; }
    .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.8']::before, .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.8']::before {
      content: '1.8'; }
    .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2']::before, .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2']::before {
      content: '2.0'; }
    .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.4']::before, .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.4']::before {
      content: '2.4'; }
    .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.8']::before, .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.8']::before {
      content: '2.8'; }
    .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='3']::before, .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='3']::before {
      content: '3.0'; }
    .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='4']::before, .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='4']::before {
      content: '4.0'; }
    .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='5']::before, .quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='5']::before {
      content: '5.0'; }
  .quill .ql-toolbar.ql-snow .ql-size.ql-picker,
  .quill .ql-toolbar.ql-snow .ql-font.ql-picker,
  .quill .ql-toolbar.ql-snow .ql-line-height.ql-picker {
    margin-bottom: 8px; }
  .quill .ql-toolbar.ql-snow .ql-header {
    clear: left; }
  .quill .ql-snow .ql-picker-label {
    color: white; }
    .quill .ql-snow .ql-picker-label:hover, .quill .ql-snow .ql-picker-label:active {
      color: white; }

.quill {
  margin-bottom: 2em;
  padding-bottom: 2.5em;
  border-bottom: none !important; }

.EditPageImage_add-button__2wzDb {
  border: none;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  cursor: pointer; }
  .EditPageImage_add-button__2wzDb:hover, .EditPageImage_add-button__2wzDb:focus {
    outline: none; }

.EditPageImage_buttons__1Y1aP {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly; }

.EditPageImage_add-button__2wzDb {
  margin: 1rem auto; }
  .EditPageImage_add-button__2wzDb svg {
    pointer-events: none;
    width: 44px;
    height: 44px;
    fill: var(--gray); }
  .EditPageImage_add-button__2wzDb:hover {
    outline: none; }

.EditPageImage_image-container__1jR_- {
  position: relative;
  touch-action: none; }
  .EditPageImage_image-container__1jR_- .EditPageImage_edit-button__3p5kB {
    text-align: center;
    width: 100%;
    position: absolute;
    top: 43%;
    top: calc(50% - 30px - 6px);
    left: 0;
    cursor: -webkit-grab;
    cursor: grab; }
    .EditPageImage_image-container__1jR_- .EditPageImage_edit-button__3p5kB svg {
      pointer-events: none;
      width: 60px;
      height: 60px;
      fill: rgba(150, 150, 150, 0.6); }
  .EditPageImage_image-container__1jR_- .EditPageImage_my-spinner__1DIWr {
    text-align: center;
    position: absolute;
    top: 45%;
    top: calc(50% - 16px - 6px);
    left: 45%;
    margin: 0 auto; }

.page-editor.quill .ql-container {
  font-size: 17px;
  line-height: 1.6;
  letter-spacing: -0.004em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  /*
  @media (min-width: $screen-sm-min) {
    font-size: $font-size-editor-desktop;
    line-height: $line-height-editor-desktop;
    letter-spacing: $letter-spacing-editor-desktop;

    ol,
    ul {
      padding-left: 2em;
    }

    blockquote {
      font-size: $font-size-editor-desktop + 1px;
      margin-bottom: $margin-bottom-editor-p-desktop;
    }

    p,
    ul {
      margin-bottom: $margin-bottom-editor-p-desktop;
    }

    h1 {
      font-size: $font-size-editor-h1-desktop;
    }
    h3 {
      font-size: $font-size-editor-h3-desktop;
    }
    h4 {
      font-size: $font-size-editor-h4-desktop;
    }
  }
  */ }
  .page-editor.quill .ql-container h1,
  .page-editor.quill .ql-container .h1,
  .page-editor.quill .ql-container h2,
  .page-editor.quill .ql-container .h2,
  .page-editor.quill .ql-container h3,
  .page-editor.quill .ql-container .h3 {
    margin-top: 0;
    margin-bottom: 30px; }
  .page-editor.quill .ql-container h1,
  .page-editor.quill .ql-container h2,
  .page-editor.quill .ql-container h3,
  .page-editor.quill .ql-container h4,
  .page-editor.quill .ql-container h5,
  .page-editor.quill .ql-container h6,
  .page-editor.quill .ql-container .h1,
  .page-editor.quill .ql-container .h2,
  .page-editor.quill .ql-container .h3,
  .page-editor.quill .ql-container .h4,
  .page-editor.quill .ql-container .h5,
  .page-editor.quill .ql-container .h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit; }
  .page-editor.quill .ql-container ol,
  .page-editor.quill .ql-container ul {
    padding-left: 1.5em; }
    .page-editor.quill .ql-container ol li,
    .page-editor.quill .ql-container ul li {
      padding-left: 0.2em;
      text-align: left; }
  .page-editor.quill .ql-container blockquote {
    font-size: 18px;
    margin-bottom: 19px;
    padding: 12.5px 25px;
    border-left: 5px solid #eeeeee; }
  .page-editor.quill .ql-container p,
  .page-editor.quill .ql-container ul,
  .page-editor.quill .ql-container .responsive-video {
    margin-bottom: 20px; }
  .page-editor.quill .ql-container h1 {
    font-size: 33px; }
  
  .page-editor.quill .ql-container h3 {
    font-size: 30px; }
  
  .page-editor.quill .ql-container h4 {
    font-size: 24px; }
  .page-editor.quill .ql-container .ql-font-opensans {
    font-family: 'Open Sans', sans-serif; }
  .page-editor.quill .ql-container .ql-font-roboto {
    font-family: 'Roboto', sans-serif; }
  .page-editor.quill .ql-container .ql-font-raleway {
    font-family: 'Raleway', sans-serif; }
  .page-editor.quill .ql-container img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block; }
  .page-editor.quill .ql-container a {
    color: #06c !important; }
    .page-editor.quill .ql-container a:hover, .page-editor.quill .ql-container a:focus {
      color: #0056b3 !important; }

.page-editor.quill {
  padding: 10px; }
  .page-editor.quill .ql-container .ql-video {
    margin-bottom: 20px; }
  .page-editor.quill .ql-container img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block; }
  .page-editor.quill:focus {
    outline: 0 !important;
    -webkit-appearance: none; }
  .page-editor.quill::after {
    font-style: normal; }
  .page-editor.quill .ql-toolbar .ql-header {
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    color: #ccc; }
    .page-editor.quill .ql-toolbar .ql-header.ql-active, .page-editor.quill .ql-toolbar .ql-header:hover, .page-editor.quill .ql-toolbar .ql-header:focus {
      color: white; }
  .page-editor.quill .ql-editor {
    padding: 0;
    line-height: inherit; }
    .page-editor.quill .ql-editor :last-child {
      margin-bottom: 0; }
  .page-editor.quill input.ql-image[type=file] {
    display: none; }
  .page-editor.quill .ql-snow .ql-picker.ql-color .ql-picker-item:nth-child(1) {
    border: 1px solid lightblue; }
  .page-editor.quill .ql-toolbar.ql-snow .ql-size.ql-picker.ql-expanded .ql-picker-options {
    top: -535px; }
  .page-editor.quill .ql-snow .ql-picker.ql-size {
    width: 60px; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label::before {
      content: '(d)'; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before {
      content: '-5'; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="13px"]::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="13px"]::before {
      content: '-4'; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before {
      content: '-3'; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="15px"]::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="15px"]::before {
      content: '-2'; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before {
      content: '-1'; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="17px"]::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="17px"]::before {
      content: '0'; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before {
      content: '+1'; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="19px"]::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="19px"]::before {
      content: '+2'; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before {
      content: '+3'; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="21px"]::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="21px"]::before {
      content: '+4'; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before {
      content: '+5'; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="23px"]::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="23px"]::before {
      content: '+6'; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before {
      content: '+7'; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="25px"]::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="25px"]::before {
      content: '+8'; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before {
      content: '+9'; }
    .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="27px"]::before, .page-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="27px"]::before {
      content: '+10'; }
  .page-editor.quill .ql-toolbar.ql-snow .ql-font.ql-picker.ql-expanded .ql-picker-options {
    top: -132px; }
  .page-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-item::before, .page-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-label::before {
    content: '(default)'; }
  .page-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="opensans"]::before, .page-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="opensans"]::before {
    content: 'Open Sans'; }
  .page-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="roboto"]::before, .page-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="roboto"]::before {
    content: 'Roboto'; }
  .page-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="raleway"]::before, .page-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="raleway"]::before {
    content: 'Raleway'; }
  .page-editor.quill .ql-toolbar.ql-snow .ql-lineheight.ql-picker.ql-expanded .ql-picker-options {
    top: -380px; }
  .page-editor.quill .ql-snow .ql-picker.ql-lineheight {
    width: 60px; }
    .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item::before, .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label::before {
      content: '(d)'; }
    .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1']::before, .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1']::before {
      content: '1.0'; }
    .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.2']::before, .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.2']::before {
      content: '1.2'; }
    .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.5']::before, .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.5']::before {
      content: '1.5'; }
    .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.6']::before, .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.6']::before {
      content: '1.6'; }
    .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.8']::before, .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.8']::before {
      content: '1.8'; }
    .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2']::before, .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2']::before {
      content: '2.0'; }
    .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.4']::before, .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.4']::before {
      content: '2.4'; }
    .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.8']::before, .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.8']::before {
      content: '2.8'; }
    .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='3']::before, .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='3']::before {
      content: '3.0'; }
    .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='4']::before, .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='4']::before {
      content: '4.0'; }
    .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='5']::before, .page-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='5']::before {
      content: '5.0'; }
  .page-editor.quill .ql-toolbar.ql-snow .ql-size.ql-picker,
  .page-editor.quill .ql-toolbar.ql-snow .ql-font.ql-picker,
  .page-editor.quill .ql-toolbar.ql-snow .ql-line-height.ql-picker {
    margin-bottom: 8px; }
  .page-editor.quill .ql-toolbar.ql-snow .ql-header {
    clear: left; }
  .page-editor.quill .ql-snow .ql-picker-label {
    color: white; }
    .page-editor.quill .ql-snow .ql-picker-label:hover, .page-editor.quill .ql-snow .ql-picker-label:active {
      color: white; }

.editor-container {
  background-color: var(--white); }

.page-editor.quill {
  word-break: break-word;
  border-bottom: none; }

.ql-toolbar.ql-snow {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  right: 0;
  background-color: var(--dark);
  border: none;
  color: var(--light);
  opacity: 1;
  z-index: 1;
  text-align: center; }

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: 1px solid #ccc; }

.ql-editor {
  padding: 0.7rem !important; }

.ql-toolbar.ql-snow .ql-header {
  color: var(--light); }
  .ql-toolbar.ql-snow .ql-header.ql-active, .ql-toolbar.ql-snow .ql-header:hover, .ql-toolbar.ql-snow .ql-header:focus {
    color: var(--blue); }

.ql-snow .ql-stroke {
  stroke: var(--light); }

.ql-snow .ql-fill {
  fill: var(--light); }

.ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill {
  fill: var(--light); }

.ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke {
  stroke: var(--light); }

.ql-snow.ql-toolbar button:hover:not(.ql-active) {
  color: var(--light); }

.ImageUploadControl_top-button__2Yjwf {
  border: none;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  cursor: pointer; }
  .ImageUploadControl_top-button__2Yjwf:hover, .ImageUploadControl_top-button__2Yjwf:focus {
    outline: none; }

.ImageUploadControl_image-upload-control__29Dvd {
  border: 1px solid #cccccc;
  padding: 1rem 0; }

.ImageUploadControl_buttons__uh89f {
  display: flex;
  flex-direction: row;
  justify-content: center; }

.ImageUploadControl_top-button__2Yjwf {
  margin: 1rem; }
  .ImageUploadControl_top-button__2Yjwf svg {
    pointer-events: none;
    width: 44px;
    height: 44px;
    fill: var(--gray); }
  .ImageUploadControl_top-button__2Yjwf:hover {
    outline: none; }

.ImageUploadControl_image-container__F_PQI {
  position: relative;
  touch-action: none; }
  .ImageUploadControl_image-container__F_PQI .ImageUploadControl_edit-button__1LyVv {
    text-align: center;
    width: 100%;
    position: absolute;
    top: 43%;
    top: calc(50% - 30px - 6px);
    left: 0;
    cursor: -webkit-grab;
    cursor: grab; }
    .ImageUploadControl_image-container__F_PQI .ImageUploadControl_edit-button__1LyVv svg {
      pointer-events: none;
      width: 60px;
      height: 60px;
      fill: rgba(150, 150, 150, 0.6); }
  .ImageUploadControl_image-container__F_PQI .ImageUploadControl_my-spinner__zaxGq {
    text-align: center;
    position: absolute;
    top: 45%;
    top: calc(50% - 16px - 6px);
    left: 45%;
    left: calc(50% - 16px - 6px);
    margin: 0 auto; }

.favorites-editor.quill .ql-container {
  font-size: 17px;
  line-height: 1.6;
  letter-spacing: -0.004em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  /*
  @media (min-width: $screen-sm-min) {
    font-size: $font-size-editor-desktop;
    line-height: $line-height-editor-desktop;
    letter-spacing: $letter-spacing-editor-desktop;

    ol,
    ul {
      padding-left: 2em;
    }

    blockquote {
      font-size: $font-size-editor-desktop + 1px;
      margin-bottom: $margin-bottom-editor-p-desktop;
    }

    p,
    ul {
      margin-bottom: $margin-bottom-editor-p-desktop;
    }

    h1 {
      font-size: $font-size-editor-h1-desktop;
    }
    h3 {
      font-size: $font-size-editor-h3-desktop;
    }
    h4 {
      font-size: $font-size-editor-h4-desktop;
    }
  }
  */ }
  .favorites-editor.quill .ql-container h1,
  .favorites-editor.quill .ql-container .h1,
  .favorites-editor.quill .ql-container h2,
  .favorites-editor.quill .ql-container .h2,
  .favorites-editor.quill .ql-container h3,
  .favorites-editor.quill .ql-container .h3 {
    margin-top: 0;
    margin-bottom: 30px; }
  .favorites-editor.quill .ql-container h1,
  .favorites-editor.quill .ql-container h2,
  .favorites-editor.quill .ql-container h3,
  .favorites-editor.quill .ql-container h4,
  .favorites-editor.quill .ql-container h5,
  .favorites-editor.quill .ql-container h6,
  .favorites-editor.quill .ql-container .h1,
  .favorites-editor.quill .ql-container .h2,
  .favorites-editor.quill .ql-container .h3,
  .favorites-editor.quill .ql-container .h4,
  .favorites-editor.quill .ql-container .h5,
  .favorites-editor.quill .ql-container .h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit; }
  .favorites-editor.quill .ql-container ol,
  .favorites-editor.quill .ql-container ul {
    padding-left: 1.5em; }
    .favorites-editor.quill .ql-container ol li,
    .favorites-editor.quill .ql-container ul li {
      padding-left: 0.2em;
      text-align: left; }
  .favorites-editor.quill .ql-container blockquote {
    font-size: 18px;
    margin-bottom: 19px;
    padding: 12.5px 25px;
    border-left: 5px solid #eeeeee; }
  .favorites-editor.quill .ql-container p,
  .favorites-editor.quill .ql-container ul,
  .favorites-editor.quill .ql-container .responsive-video {
    margin-bottom: 20px; }
  .favorites-editor.quill .ql-container h1 {
    font-size: 33px; }
  
  .favorites-editor.quill .ql-container h3 {
    font-size: 30px; }
  
  .favorites-editor.quill .ql-container h4 {
    font-size: 24px; }
  .favorites-editor.quill .ql-container .ql-font-opensans {
    font-family: 'Open Sans', sans-serif; }
  .favorites-editor.quill .ql-container .ql-font-roboto {
    font-family: 'Roboto', sans-serif; }
  .favorites-editor.quill .ql-container .ql-font-raleway {
    font-family: 'Raleway', sans-serif; }
  .favorites-editor.quill .ql-container img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block; }
  .favorites-editor.quill .ql-container a {
    color: #06c !important; }
    .favorites-editor.quill .ql-container a:hover, .favorites-editor.quill .ql-container a:focus {
      color: #0056b3 !important; }

.favorites-editor.quill {
  padding: 10px; }
  .favorites-editor.quill .ql-container .ql-video {
    margin-bottom: 20px; }
  .favorites-editor.quill .ql-container img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block; }
  .favorites-editor.quill:focus {
    outline: 0 !important;
    -webkit-appearance: none; }
  .favorites-editor.quill::after {
    font-style: normal; }
  .favorites-editor.quill .ql-toolbar .ql-header {
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    color: #ccc; }
    .favorites-editor.quill .ql-toolbar .ql-header.ql-active, .favorites-editor.quill .ql-toolbar .ql-header:hover, .favorites-editor.quill .ql-toolbar .ql-header:focus {
      color: white; }
  .favorites-editor.quill .ql-editor {
    padding: 0;
    line-height: inherit; }
    .favorites-editor.quill .ql-editor :last-child {
      margin-bottom: 0; }
  .favorites-editor.quill input.ql-image[type=file] {
    display: none; }
  .favorites-editor.quill .ql-snow .ql-picker.ql-color .ql-picker-item:nth-child(1) {
    border: 1px solid lightblue; }
  .favorites-editor.quill .ql-toolbar.ql-snow .ql-size.ql-picker.ql-expanded .ql-picker-options {
    top: -535px; }
  .favorites-editor.quill .ql-snow .ql-picker.ql-size {
    width: 60px; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label::before {
      content: '(d)'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before {
      content: '-5'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="13px"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="13px"]::before {
      content: '-4'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before {
      content: '-3'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="15px"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="15px"]::before {
      content: '-2'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before {
      content: '-1'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="17px"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="17px"]::before {
      content: '0'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before {
      content: '+1'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="19px"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="19px"]::before {
      content: '+2'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before {
      content: '+3'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="21px"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="21px"]::before {
      content: '+4'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before {
      content: '+5'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="23px"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="23px"]::before {
      content: '+6'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before {
      content: '+7'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="25px"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="25px"]::before {
      content: '+8'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before {
      content: '+9'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="27px"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="27px"]::before {
      content: '+10'; }
  .favorites-editor.quill .ql-toolbar.ql-snow .ql-font.ql-picker.ql-expanded .ql-picker-options {
    top: -132px; }
  .favorites-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-item::before, .favorites-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-label::before {
    content: '(default)'; }
  .favorites-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="opensans"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="opensans"]::before {
    content: 'Open Sans'; }
  .favorites-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="roboto"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="roboto"]::before {
    content: 'Roboto'; }
  .favorites-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="raleway"]::before, .favorites-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="raleway"]::before {
    content: 'Raleway'; }
  .favorites-editor.quill .ql-toolbar.ql-snow .ql-lineheight.ql-picker.ql-expanded .ql-picker-options {
    top: -380px; }
  .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight {
    width: 60px; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item::before, .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label::before {
      content: '(d)'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1']::before, .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1']::before {
      content: '1.0'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.2']::before, .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.2']::before {
      content: '1.2'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.5']::before, .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.5']::before {
      content: '1.5'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.6']::before, .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.6']::before {
      content: '1.6'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.8']::before, .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.8']::before {
      content: '1.8'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2']::before, .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2']::before {
      content: '2.0'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.4']::before, .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.4']::before {
      content: '2.4'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.8']::before, .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.8']::before {
      content: '2.8'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='3']::before, .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='3']::before {
      content: '3.0'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='4']::before, .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='4']::before {
      content: '4.0'; }
    .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='5']::before, .favorites-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='5']::before {
      content: '5.0'; }
  .favorites-editor.quill .ql-toolbar.ql-snow .ql-size.ql-picker,
  .favorites-editor.quill .ql-toolbar.ql-snow .ql-font.ql-picker,
  .favorites-editor.quill .ql-toolbar.ql-snow .ql-line-height.ql-picker {
    margin-bottom: 8px; }
  .favorites-editor.quill .ql-toolbar.ql-snow .ql-header {
    clear: left; }
  .favorites-editor.quill .ql-snow .ql-picker-label {
    color: white; }
    .favorites-editor.quill .ql-snow .ql-picker-label:hover, .favorites-editor.quill .ql-snow .ql-picker-label:active {
      color: white; }

.favorites-editor.quill {
  margin-bottom: 2em;
  padding-bottom: 2.5em;
  border-bottom: none !important; }
  .favorites-editor.quill .ql-container .ql-editor {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    line-height: 1.6;
    color: #000000; }

.content-item-teaser {
  display: block;
  padding: 0;
  border: 1px solid #eddfd4;
  color: #636b6f;
  background-color: white;
  margin-bottom: 10px;
  font-size: 14px;
  margin-bottom: 10px;
  width: 100%;
  position: relative; }
  .content-item-teaser:focus, .content-item-teaser:hover {
    text-decoration: none;
    background-color: #fefcfa;
    color: #636b6f; }
  .content-item-teaser .controls {
    position: absolute;
    right: 0;
    top: 0; }
  .content-item-teaser .content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start; }
    .content-item-teaser .content .row-first {
      width: 100%; }
      .content-item-teaser .content .row-first .photo-container {
        background-color: #cccccc;
        position: relative;
        min-height: 50px; }
        .content-item-teaser .content .row-first .photo-container .photo {
          border: none; }
          .content-item-teaser .content .row-first .photo-container .photo img {
            width: 100%; }
    .content-item-teaser .content .row-second {
      width: 100%;
      padding: 8px 14px 8px 10px;
      text-align: left;
      justify-content: flex-end; }
      .content-item-teaser .content .row-second .info {
        font-family: "Foco", serif;
        font-size: 12px;
        color: #878787;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        margin-bottom: 8px; }
        .content-item-teaser .content .row-second .info .delimiter {
          padding: 0 4px; }
      .content-item-teaser .content .row-second .title {
        font-family: "Lora", serif;
        font-size: 18px;
        color: #2c2c2c;
        margin-bottom: 6px; }
      .content-item-teaser .content .row-second .body {
        font-family: "Foco", serif;
        font-size: 16px;
        color: #878787; }

.top-menu {
  background-color: #f8f8f8; }
  .top-menu button,
  .top-menu a,
  .top-menu span {
    padding: 8px 14px;
    text-decoration: none;
    color: buttontext; }
    .top-menu button:hover, .top-menu button:focus,
    .top-menu a:hover,
    .top-menu a:focus,
    .top-menu span:hover,
    .top-menu span:focus {
      outline: none;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      text-decoration: none;
      color: buttontext; }
    .top-menu button.active,
    .top-menu a.active,
    .top-menu span.active {
      color: var(--primary); }
  .top-menu .separator {
    width: 2px; }
    .top-menu .separator .bg {
      background-color: #ddd;
      height: 20px; }

.top-menu button,
.top-menu a,
.top-menu span, .delete-btn {
  border: none;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  cursor: pointer; }
  .top-menu button:hover,
  .top-menu a:hover,
  .top-menu span:hover, .delete-btn:hover, .top-menu button:focus,
  .top-menu a:focus,
  .top-menu span:focus, .delete-btn:focus {
    outline: none; }

.delete-btn {
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--white);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .delete-btn svg {
    margin: 0 auto;
    pointer-events: none;
    width: 16px;
    height: 16px;
    stroke: var(--white);
    fill: var(--white); }

.delete-btn:hover, .delete-btn:active {
  text-decoration: none !important; }

.bottom-bar .add-button svg {
  pointer-events: none;
  width: 30px;
  height: 30px;
  fill: var(--gray); }

.favorites.container-fluid {
  padding-right: 10px;
  padding-left: 10px; }

.favorites .row {
  margin-left: -5px;
  margin-right: -5px; }

.favorites .col-sm {
  padding-left: 5px;
  padding-right: 5px; }

.favorites .favorite:focus, .favorites .favorite:hover {
  text-decoration: none;
  color: #636b6f; }

.top-menu {
  background-color: #f8f8f8; }
  .top-menu button,
  .top-menu a,
  .top-menu span {
    padding: 8px 14px;
    text-decoration: none;
    color: buttontext; }
    .top-menu button:hover, .top-menu button:focus,
    .top-menu a:hover,
    .top-menu a:focus,
    .top-menu span:hover,
    .top-menu span:focus {
      outline: none;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      text-decoration: none;
      color: buttontext; }
    .top-menu button.active,
    .top-menu a.active,
    .top-menu span.active {
      color: var(--primary); }
  .top-menu .separator {
    width: 2px; }
    .top-menu .separator .bg {
      background-color: #ddd;
      height: 20px; }

.top-menu button,
.top-menu a,
.top-menu span, .stories .story .delete-btn {
  border: none;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  cursor: pointer; }
  .top-menu button:hover,
  .top-menu a:hover,
  .top-menu span:hover, .stories .story .delete-btn:hover, .top-menu button:focus,
  .top-menu a:focus,
  .top-menu span:focus, .stories .story .delete-btn:focus {
    outline: none; }

.stories .story .delete-btn {
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--white);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .stories .story .delete-btn svg {
    margin: 0 auto;
    pointer-events: none;
    width: 16px;
    height: 16px;
    stroke: var(--white);
    fill: var(--white); }

.stories .story .title-and-subtitle .subtitle, .stories .story .title-and-subtitle .title {
  text-align: center;
  color: #ffffff;
  background-color: transparent;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  word-wrap: break-word; }
  .stories .story .title-and-subtitle .subtitle div, .stories .story .title-and-subtitle .title div {
    margin-left: 15px;
    margin-right: 15px;
    font-size: 2rem; }

.stories .story .title-and-subtitle .subtitle div {
  font-size: 1rem; }

.stories .story .title-and-subtitle {
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%; }

.stories .CLASSIC.story .img-container:after {
  background-color: rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ''; }

.stories .CLASSIC.story .img-container:after {
  background-color: rgba(0, 0, 0, 0.15); }

.stories .CLASSIC.story .title-and-subtitle {
  bottom: 70px; }
  .stories .CLASSIC.story .title-and-subtitle .title {
    margin-bottom: 16px; }
    .stories .CLASSIC.story .title-and-subtitle .title div {
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
      font-size: 3em;
      line-height: 1.17188;
      letter-spacing: 0.03em; }
  .stories .CLASSIC.story .title-and-subtitle .subtitle div {
    font-style: italic;
    font-family: 'Libre Baskerville', serif;
    font-weight: 400;
    font-size: 1.33333em;
    line-height: 1.17188;
    letter-spacing: 0em; }

.stories .NICO.story .img-container:after {
  background-color: rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ''; }

.stories .NICO.story .img-container:after {
  background-color: rgba(0, 0, 0, 0.25); }

.stories .NICO.story .title-and-subtitle {
  top: 0;
  bottom: 32px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .stories .NICO.story .title-and-subtitle .title div {
    font-size: 4.16667em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    line-height: 1.17188;
    letter-spacing: 0em; }
  .stories .NICO.story .title-and-subtitle .subtitle {
    margin-top: 7px; }
    .stories .NICO.story .title-and-subtitle .subtitle div {
      font-size: 1.33333em;
      font-family: 'Lora', serif;
      line-height: 1.17188;
      letter-spacing: 0.03em; }

.stories .WANDER.story .img-container:after {
  background-color: rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ''; }

.stories .WANDER.story .title-and-subtitle {
  top: 0;
  bottom: unset;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%; }
  .stories .WANDER.story .title-and-subtitle .title {
    margin-top: 30%;
    width: 80%;
    background-image: url(/img/CoverPageLayouts/misc/wander/top-border.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    border-bottom: 1px solid var(--white);
    padding-bottom: 17px; }
    .stories .WANDER.story .title-and-subtitle .title div {
      padding-top: 24px;
      font-size: 3.33333em;
      font-family: 'Roboto Slab', serif;
      line-height: 1.17188;
      letter-spacing: 0.05em; }
  .stories .WANDER.story .title-and-subtitle .subtitle {
    margin-bottom: 60px; }
    .stories .WANDER.story .title-and-subtitle .subtitle div {
      font-size: 1.5em;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 300;
      line-height: 1.17188;
      letter-spacing: 0em; }

.stories .HYDE.story .img-container:after {
  background-color: rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ''; }

.stories .HYDE.story .title-and-subtitle {
  background-image: url(/img/CoverPageLayouts/misc/hyde/frame.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: unset;
  top: 22px;
  bottom: 54px;
  left: 32px;
  right: 32px; }
  .stories .HYDE.story .title-and-subtitle .title {
    margin-top: 18%; }
    .stories .HYDE.story .title-and-subtitle .title div {
      font-family: 'Playfair Display', serif;
      font-size: 3.33333em;
      line-height: 1.17188;
      letter-spacing: 0em; }
  .stories .HYDE.story .title-and-subtitle .subtitle {
    position: absolute;
    bottom: 12%; }
    .stories .HYDE.story .title-and-subtitle .subtitle div {
      font-family: 'Playfair Display', serif;
      font-style: italic;
      font-size: 1.33333em;
      line-height: 1.17188;
      letter-spacing: 0em; }

.stories .SURFACE.story .img-container:after {
  background-color: rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ''; }

.stories .SURFACE.story .title-and-subtitle {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  .stories .SURFACE.story .title-and-subtitle .title {
    background-color: var(--white);
    margin-top: 20%;
    width: 70%;
    max-width: 200px;
    padding: 14px 12px; }
    .stories .SURFACE.story .title-and-subtitle .title div {
      color: #000000;
      margin: 0;
      font-family: 'Raleway', sans-serif;
      font-weight: bold;
      font-size: 1.83333em;
      line-height: 1.44141;
      letter-spacing: 0.35em; }
  .stories .SURFACE.story .title-and-subtitle .subtitle {
    position: absolute;
    bottom: 12%; }
    .stories .SURFACE.story .title-and-subtitle .subtitle div {
      font-family: 'Raleway', sans-serif;
      font-weight: 500;
      font-size: 1.16667em;
      line-height: 1.44141;
      letter-spacing: 0.25em; }

.stories .LAMODE.story .img-container:after {
  background-color: rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ''; }

.stories .LAMODE.story .img-container:after {
  background-color: rgba(0, 0, 0, 0.2); }

.stories .LAMODE.story .title-and-subtitle {
  top: 0;
  bottom: 32px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .stories .LAMODE.story .title-and-subtitle .title div {
    font-family: 'Prata', serif;
    font-size: 3.33333em;
    line-height: 1.17188;
    letter-spacing: 0.05em; }
  .stories .LAMODE.story .title-and-subtitle .subtitle {
    margin-top: 9px; }
    .stories .LAMODE.story .title-and-subtitle .subtitle div {
      padding-top: 24px;
      border-top: 3px solid #ffffff;
      max-width: 205px;
      margin: 0 auto;
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      font-size: 1.5em;
      line-height: 1.17188;
      letter-spacing: 0em; }

.delete-btn:hover, .delete-btn:active {
  text-decoration: none !important; }

.bottom-bar .add-button svg {
  pointer-events: none;
  width: 30px;
  height: 30px;
  fill: var(--gray); }

.stories {
  margin-left: auto;
  margin-right: auto;
  background-color: #e5e5e5; }
  .stories .story {
    box-shadow: 0 0 4px 0 rgba(204, 204, 204, 0.7);
    text-align: center;
    width: 250px;
    height: 375px;
    margin: 15px;
    background-color: var(--white);
    background-repeat: no-repeat;
    background-size: cover;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--white);
    text-decoration: none;
    z-index: 1;
    position: relative; }
    .stories .story.COVER.has-bg:after {
      background-color: rgba(0, 0, 0, 0.07);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: ''; }
    .stories .story.COVER.has-bg.CLASSIC:after {
      background-color: rgba(0, 0, 0, 0.15); }
    .stories .story.COVER.has-bg.NICO:after {
      background-color: rgba(0, 0, 0, 0.25); }
    .stories .story.COVER.has-bg.LAMODE:after {
      background-color: rgba(0, 0, 0, 0.2); }
    .stories .story .info {
      overflow-wrap: break-word;
      background-color: rgba(34, 34, 34, 0.5);
      color: white;
      z-index: 1; }
      .stories .story .info .details .location {
        padding-right: 2px;
        text-align: left; }
      .stories .story .info .details .date {
        text-align: right;
        padding-left: 2px;
        text-transform: capitalize; }

@media (min-width: 576px) {
  .stories .story {
    width: 300px;
    height: 450px; } }

@media (min-width: 992px) {
  .stories .story {
    width: 400px;
    height: 600px; } }

.cover-page-layout-selector .cover-layout input[type="radio"] {
  display: none; }

.cover-page-layout-selector .cover-layout label {
  cursor: pointer;
  font-size: 14px;
  border: 1px solid transparent; }

.cover-page-layout-selector .cover-layout input[type="radio"]:checked + label {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: var(--white); }

.page .layout-wrapper .text-container {
  font-size: 17px;
  line-height: 1.6;
  letter-spacing: -0.004em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  /*
  @media (min-width: $screen-sm-min) {
    font-size: $font-size-editor-desktop;
    line-height: $line-height-editor-desktop;
    letter-spacing: $letter-spacing-editor-desktop;

    ol,
    ul {
      padding-left: 2em;
    }

    blockquote {
      font-size: $font-size-editor-desktop + 1px;
      margin-bottom: $margin-bottom-editor-p-desktop;
    }

    p,
    ul {
      margin-bottom: $margin-bottom-editor-p-desktop;
    }

    h1 {
      font-size: $font-size-editor-h1-desktop;
    }
    h3 {
      font-size: $font-size-editor-h3-desktop;
    }
    h4 {
      font-size: $font-size-editor-h4-desktop;
    }
  }
  */ }
  .page .layout-wrapper .text-container h1,
  .page .layout-wrapper .text-container .h1,
  .page .layout-wrapper .text-container h2,
  .page .layout-wrapper .text-container .h2,
  .page .layout-wrapper .text-container h3,
  .page .layout-wrapper .text-container .h3 {
    margin-top: 0;
    margin-bottom: 30px; }
  .page .layout-wrapper .text-container h1,
  .page .layout-wrapper .text-container h2,
  .page .layout-wrapper .text-container h3,
  .page .layout-wrapper .text-container h4,
  .page .layout-wrapper .text-container h5,
  .page .layout-wrapper .text-container h6,
  .page .layout-wrapper .text-container .h1,
  .page .layout-wrapper .text-container .h2,
  .page .layout-wrapper .text-container .h3,
  .page .layout-wrapper .text-container .h4,
  .page .layout-wrapper .text-container .h5,
  .page .layout-wrapper .text-container .h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit; }
  .page .layout-wrapper .text-container ol,
  .page .layout-wrapper .text-container ul {
    padding-left: 1.5em; }
    .page .layout-wrapper .text-container ol li,
    .page .layout-wrapper .text-container ul li {
      padding-left: 0.2em;
      text-align: left; }
  .page .layout-wrapper .text-container blockquote {
    font-size: 18px;
    margin-bottom: 19px;
    padding: 12.5px 25px;
    border-left: 5px solid #eeeeee; }
  .page .layout-wrapper .text-container p,
  .page .layout-wrapper .text-container ul,
  .page .layout-wrapper .text-container .responsive-video {
    margin-bottom: 20px; }
  .page .layout-wrapper .text-container h1 {
    font-size: 33px; }
  
  .page .layout-wrapper .text-container h3 {
    font-size: 30px; }
  
  .page .layout-wrapper .text-container h4 {
    font-size: 24px; }
  .page .layout-wrapper .text-container .ql-font-opensans {
    font-family: 'Open Sans', sans-serif; }
  .page .layout-wrapper .text-container .ql-font-roboto {
    font-family: 'Roboto', sans-serif; }
  .page .layout-wrapper .text-container .ql-font-raleway {
    font-family: 'Raleway', sans-serif; }
  .page .layout-wrapper .text-container img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block; }
  .page .layout-wrapper .text-container a {
    color: #06c !important; }
    .page .layout-wrapper .text-container a:hover, .page .layout-wrapper .text-container a:focus {
      color: #0056b3 !important; }

.page .layout-wrapper .delete-btn {
  border: none;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  cursor: pointer; }
  .page .layout-wrapper .delete-btn:hover, .page .layout-wrapper .delete-btn:focus {
    outline: none; }

.page .layout-wrapper .delete-btn {
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--white);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .page .layout-wrapper .delete-btn svg {
    margin: 0 auto;
    pointer-events: none;
    width: 16px;
    height: 16px;
    stroke: var(--white);
    fill: var(--white); }

.page .layout-wrapper.COVER .title-and-subtitle .subtitle, .page .layout-wrapper.COVER .title-and-subtitle .title {
  text-align: center;
  color: #ffffff;
  background-color: transparent;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  word-wrap: break-word; }
  .page .layout-wrapper.COVER .title-and-subtitle .subtitle div, .page .layout-wrapper.COVER .title-and-subtitle .title div {
    margin-left: 15px;
    margin-right: 15px;
    font-size: 2rem; }

.page .layout-wrapper.COVER .title-and-subtitle .subtitle div {
  font-size: 1rem; }

.page .layout-wrapper.COVER .title-and-subtitle {
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%; }

.page .CLASSIC.layout-wrapper.COVER .img-container:after {
  background-color: rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ''; }

.page .CLASSIC.layout-wrapper.COVER .img-container:after {
  background-color: rgba(0, 0, 0, 0.15); }

.page .CLASSIC.layout-wrapper.COVER .title-and-subtitle {
  bottom: 70px; }
  .page .CLASSIC.layout-wrapper.COVER .title-and-subtitle .title {
    margin-bottom: 16px; }
    .page .CLASSIC.layout-wrapper.COVER .title-and-subtitle .title div {
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
      font-size: 3em;
      line-height: 1.17188;
      letter-spacing: 0.03em; }
  .page .CLASSIC.layout-wrapper.COVER .title-and-subtitle .subtitle div {
    font-style: italic;
    font-family: 'Libre Baskerville', serif;
    font-weight: 400;
    font-size: 1.33333em;
    line-height: 1.17188;
    letter-spacing: 0em; }

.page .NICO.layout-wrapper.COVER .img-container:after {
  background-color: rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ''; }

.page .NICO.layout-wrapper.COVER .img-container:after {
  background-color: rgba(0, 0, 0, 0.25); }

.page .NICO.layout-wrapper.COVER .title-and-subtitle {
  top: 0;
  bottom: 32px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .page .NICO.layout-wrapper.COVER .title-and-subtitle .title div {
    font-size: 4.16667em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    line-height: 1.17188;
    letter-spacing: 0em; }
  .page .NICO.layout-wrapper.COVER .title-and-subtitle .subtitle {
    margin-top: 7px; }
    .page .NICO.layout-wrapper.COVER .title-and-subtitle .subtitle div {
      font-size: 1.33333em;
      font-family: 'Lora', serif;
      line-height: 1.17188;
      letter-spacing: 0.03em; }

.page .WANDER.layout-wrapper.COVER .img-container:after {
  background-color: rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ''; }

.page .WANDER.layout-wrapper.COVER .title-and-subtitle {
  top: 0;
  bottom: unset;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%; }
  .page .WANDER.layout-wrapper.COVER .title-and-subtitle .title {
    margin-top: 30%;
    width: 80%;
    background-image: url(/img/CoverPageLayouts/misc/wander/top-border.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    border-bottom: 1px solid var(--white);
    padding-bottom: 17px; }
    .page .WANDER.layout-wrapper.COVER .title-and-subtitle .title div {
      padding-top: 24px;
      font-size: 3.33333em;
      font-family: 'Roboto Slab', serif;
      line-height: 1.17188;
      letter-spacing: 0.05em; }
  .page .WANDER.layout-wrapper.COVER .title-and-subtitle .subtitle {
    margin-bottom: 60px; }
    .page .WANDER.layout-wrapper.COVER .title-and-subtitle .subtitle div {
      font-size: 1.5em;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 300;
      line-height: 1.17188;
      letter-spacing: 0em; }

.page .HYDE.layout-wrapper.COVER .img-container:after {
  background-color: rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ''; }

.page .HYDE.layout-wrapper.COVER .title-and-subtitle {
  background-image: url(/img/CoverPageLayouts/misc/hyde/frame.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: unset;
  top: 22px;
  bottom: 54px;
  left: 32px;
  right: 32px; }
  .page .HYDE.layout-wrapper.COVER .title-and-subtitle .title {
    margin-top: 18%; }
    .page .HYDE.layout-wrapper.COVER .title-and-subtitle .title div {
      font-family: 'Playfair Display', serif;
      font-size: 3.33333em;
      line-height: 1.17188;
      letter-spacing: 0em; }
  .page .HYDE.layout-wrapper.COVER .title-and-subtitle .subtitle {
    position: absolute;
    bottom: 12%; }
    .page .HYDE.layout-wrapper.COVER .title-and-subtitle .subtitle div {
      font-family: 'Playfair Display', serif;
      font-style: italic;
      font-size: 1.33333em;
      line-height: 1.17188;
      letter-spacing: 0em; }

.page .SURFACE.layout-wrapper.COVER .img-container:after {
  background-color: rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ''; }

.page .SURFACE.layout-wrapper.COVER .title-and-subtitle {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  .page .SURFACE.layout-wrapper.COVER .title-and-subtitle .title {
    background-color: var(--white);
    margin-top: 20%;
    width: 70%;
    max-width: 200px;
    padding: 14px 12px; }
    .page .SURFACE.layout-wrapper.COVER .title-and-subtitle .title div {
      color: #000000;
      margin: 0;
      font-family: 'Raleway', sans-serif;
      font-weight: bold;
      font-size: 1.83333em;
      line-height: 1.44141;
      letter-spacing: 0.35em; }
  .page .SURFACE.layout-wrapper.COVER .title-and-subtitle .subtitle {
    position: absolute;
    bottom: 12%; }
    .page .SURFACE.layout-wrapper.COVER .title-and-subtitle .subtitle div {
      font-family: 'Raleway', sans-serif;
      font-weight: 500;
      font-size: 1.16667em;
      line-height: 1.44141;
      letter-spacing: 0.25em; }

.page .LAMODE.layout-wrapper.COVER .img-container:after {
  background-color: rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ''; }

.page .LAMODE.layout-wrapper.COVER .img-container:after {
  background-color: rgba(0, 0, 0, 0.2); }

.page .LAMODE.layout-wrapper.COVER .title-and-subtitle {
  top: 0;
  bottom: 32px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .page .LAMODE.layout-wrapper.COVER .title-and-subtitle .title div {
    font-family: 'Prata', serif;
    font-size: 3.33333em;
    line-height: 1.17188;
    letter-spacing: 0.05em; }
  .page .LAMODE.layout-wrapper.COVER .title-and-subtitle .subtitle {
    margin-top: 9px; }
    .page .LAMODE.layout-wrapper.COVER .title-and-subtitle .subtitle div {
      padding-top: 24px;
      border-top: 3px solid #ffffff;
      max-width: 205px;
      margin: 0 auto;
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      font-size: 1.5em;
      line-height: 1.17188;
      letter-spacing: 0em; }

@font-face {
  font-family: 'EpicRide';
  font-style: normal;
  font-weight: normal;
  src: local("EpicRide"), url("/fonts/EpicRide/EpicRide.woff2") format("woff2"), url("/fonts/EpicRide/EpicRide.woff") format("woff"), url("/fonts/EpicRide/EpicRide.ttf") format("ttf"); }

@font-face {
  font-family: 'Foco';
  font-style: normal;
  font-weight: normal;
  src: local("Foco"), url("/fonts/Foco/FOCO.woff") format("woff"); }

@font-face {
  font-family: 'Foco Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Foco Bold"), url("/fonts/Foco/FOCOBOLD.woff") format("woff"); }

.page {
  height: 100%;
  min-height: 100%; }
  .page .layout-wrapper {
    height: 100%;
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column; }
    .page .layout-wrapper .img-container {
      background-color: #cccccc;
      position: relative;
      touch-action: none;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: cover; }
      .page .layout-wrapper .img-container .edit-button {
        text-align: center;
        width: 100%;
        position: absolute;
        top: 43%;
        top: calc(50% - 20px - 6px);
        left: 0; }
        .page .layout-wrapper .img-container .edit-button svg {
          pointer-events: none;
          width: 40px;
          height: 40px;
          margin-left: 4px; }
    .page .layout-wrapper .text-container {
      word-break: break-word;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .page .layout-wrapper .text-container *:nth-last-child(1) {
        margin-bottom: 0; }
      .page .layout-wrapper .text-container .responsive-video {
        min-height: 162px; }
      .page .layout-wrapper .text-container.has-scroll {
        justify-content: flex-start; }
      .page .layout-wrapper .text-container p {
        text-align: left; }
    .page .layout-wrapper.IMAGE .img-container, .page .layout-wrapper.FRAMED_IMAGE .img-container, .page .layout-wrapper.COVER .img-container {
      height: 100%;
      min-height: 100%; }
      .page .layout-wrapper.IMAGE .img-container .image, .page .layout-wrapper.FRAMED_IMAGE .img-container .image, .page .layout-wrapper.COVER .img-container .image {
        height: 100%;
        min-height: 100%; }
    .page .layout-wrapper.FRAMED_IMAGE {
      padding: 15px; }
    .page .layout-wrapper.TWO_IMAGES .img-container {
      height: 50%;
      min-height: 187.5px; }
    .page .layout-wrapper.TWO_FRAMED_IMAGES .img-container {
      width: calc(100% - 20px);
      height: calc(50% - 15px);
      min-height: 172.5px;
      box-sizing: border-box;
      margin: 10px 10px 5px 10px; }
      .page .layout-wrapper.TWO_FRAMED_IMAGES .img-container:nth-last-child(1) {
        margin-top: 5px; }
    .page .layout-wrapper.IMAGE_WITH_TITLE .img-container, .page .layout-wrapper.QUOTE .img-container {
      height: 100%;
      min-height: 100%; }
      .page .layout-wrapper.IMAGE_WITH_TITLE .img-container .edit-button, .page .layout-wrapper.QUOTE .img-container .edit-button {
        top: 50px; }
    .page .layout-wrapper.IMAGE_WITH_TITLE .text-container, .page .layout-wrapper.QUOTE .text-container {
      position: absolute;
      top: 38%;
      padding: 1.8rem;
      width: 100%;
      height: auto;
      min-height: 50px;
      overflow-y: auto;
      word-wrap: break-word;
      color: var(--light);
      text-shadow: 0px 0px 5px var(--dark);
      border-top: 2px dashed transparent;
      border-bottom: 2px dashed transparent; }
      .page .layout-wrapper.IMAGE_WITH_TITLE .text-container *, .page .layout-wrapper.QUOTE .text-container * {
        margin-bottom: inherit; }
      .page .layout-wrapper.IMAGE_WITH_TITLE .text-container.edit, .page .layout-wrapper.QUOTE .text-container.edit {
        border-top: 2px dashed var(--light);
        border-bottom: 2px dashed var(--light); }
    .page .layout-wrapper.QUOTE {
      border: none; }
      .page .layout-wrapper.QUOTE .img-container {
        position: relative; }
        .page .layout-wrapper.QUOTE .img-container .edit-button {
          top: unset;
          bottom: 20px; }
        .page .layout-wrapper.QUOTE .img-container::before {
          background-color: #000000;
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          opacity: 0.3; }
      .page .layout-wrapper.QUOTE .text-container {
        z-index: 2;
        min-height: 100%;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .page .layout-wrapper.QUOTE .text-container blockquote {
          text-align: center;
          font-size: 2rem;
          line-height: 1.61798;
          text-shadow: none;
          font-family: 'Prata', serif;
          border-left: none; }
          .page .layout-wrapper.QUOTE .text-container blockquote em {
            text-align: center;
            font-size: 1rem;
            line-height: 1.6438;
            letter-spacing: 0.2rem;
            font-style: normal;
            text-shadow: none;
            font-family: 'Open Sans', sans-serif;
            font-weight: 300; }
        .page .layout-wrapper.QUOTE .text-container.edit {
          top: 10%;
          border: none;
          min-height: unset;
          max-height: 270px;
          height: 270px;
          overflow-y: hidden; }
          .page .layout-wrapper.QUOTE .text-container.edit blockquote {
            font-size: 24px; }
            .page .layout-wrapper.QUOTE .text-container.edit blockquote em {
              font-size: 14px; }
    .page .layout-wrapper.IMAGE_AND_TEXT .img-container, .page .layout-wrapper.FRAMED_IMAGE_AND_TEXT .img-container {
      height: 60%;
      min-height: 250px; }
    .page .layout-wrapper.IMAGE_AND_TEXT .text-container, .page .layout-wrapper.FRAMED_IMAGE_AND_TEXT .text-container {
      padding: 1.8rem;
      height: 40%;
      min-height: 125px;
      overflow-y: auto;
      word-wrap: break-word; }
    .page .layout-wrapper.FRAMED_IMAGE_AND_TEXT {
      padding: 30px; }
      .page .layout-wrapper.FRAMED_IMAGE_AND_TEXT .img-container {
        min-height: 190px !important;
        background-position: center center;
        position: relative;
        border: 5px solid #eeeeee; }
    .page .layout-wrapper.TEXT {
      height: 100%;
      min-height: 100%; }
      .page .layout-wrapper.TEXT .text-container {
        padding: 1.8rem;
        height: 100%;
        min-height: 100%;
        overflow-y: auto;
        word-wrap: break-word; }
    .page .layout-wrapper.COVER {
      display: flex;
      flex-direction: column;
      justify-content: flex-end; }
      .page .layout-wrapper.COVER .img-container .edit-button {
        top: 20px;
        text-align: right;
        padding-right: 20px; }
      .page .layout-wrapper.COVER.COVER.has-bg:after {
        background-color: rgba(0, 0, 0, 0.07);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: ''; }
      .page .layout-wrapper.COVER.COVER.has-bg.CLASSIC:after {
        background-color: rgba(0, 0, 0, 0.15); }
      .page .layout-wrapper.COVER.COVER.has-bg.NICO:after {
        background-color: rgba(0, 0, 0, 0.25); }
      .page .layout-wrapper.COVER.COVER.has-bg.LAMODE:after {
        background-color: rgba(0, 0, 0, 0.2); }
      .page .layout-wrapper.COVER .info {
        overflow-wrap: break-word;
        background-color: rgba(34, 34, 34, 0.5);
        color: white;
        position: absolute;
        width: 100%;
        bottom: 0;
        z-index: 1; }
        .page .layout-wrapper.COVER .info .details .location {
          padding-right: 2px;
          text-align: left; }
        .page .layout-wrapper.COVER .info .details .date {
          text-align: right;
          padding-left: 2px;
          text-transform: capitalize; }

@media (min-width: 576px) {
  .page .layout-wrapper.IMAGE_WITH_TITLE .text-container {
    top: 40%; }
  .page .layout-wrapper.IMAGE_AND_TEXT .img-container {
    height: 300px;
    min-height: 300px; }
  .page .layout-wrapper.IMAGE_AND_TEXT .text-container {
    height: 150px;
    min-height: 150px; }
  .page .layout-wrapper.FRAMED_IMAGE_AND_TEXT {
    min-height: 240px; }
  .page .layout-wrapper.QUOTE .text-container blockquote {
    font-size: 2.5rem; }
    .page .layout-wrapper.QUOTE .text-container blockquote em {
      font-size: 1.35rem; } }

@media (min-width: 992px) {
  .page .layout-wrapper.IMAGE_WITH_TITLE .text-container {
    top: 43%; }
  .page .layout-wrapper.IMAGE_AND_TEXT .img-container {
    height: 400px;
    min-height: 400px; }
  .page .layout-wrapper.IMAGE_AND_TEXT .text-container {
    height: 200px;
    min-height: 200px; } }

.layout-panel .layouts .layout {
  border: none;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  cursor: pointer; }
  .layout-panel .layouts .layout:hover, .layout-panel .layouts .layout:focus {
    outline: none; }

.pages {
  margin-left: auto;
  margin-right: auto;
  background-color: #e5e5e5; }
  .pages .page-container {
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 250px;
    height: 375px;
    background-color: var(--white);
    -webkit-transition: border-width 0.2s ease-in-out;
    transition: border-width 0.2s ease-in-out; }
    .pages .page-container.swiper-slide-active {
      border-width: 0; }
    .pages .page-container .page {
      box-shadow: 0 0 4px 0 rgba(204, 204, 204, 0.7); }

.layout-panel {
  background-color: var(--dark);
  color: var(--light);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  display: none;
  z-index: 5; }
  .layout-panel.show {
    display: block; }
  .layout-panel .close-button {
    background-color: var(--gray);
    border-radius: 50%;
    position: absolute;
    top: -15px;
    right: calc(50% - 15px);
    width: 34px;
    height: 34px;
    line-height: 26px;
    text-align: center;
    z-index: 3;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .layout-panel .close-button svg {
      margin: 0 auto;
      pointer-events: none;
      width: 16px;
      height: 16px;
      stroke: var(--white);
      fill: var(--white); }
  .layout-panel .layouts {
    overflow-x: auto;
    border: 1px solid var(--light);
    box-sizing: border-box;
    border-radius: 4px; }
    .layout-panel .layouts .layout {
      margin: 0; }
      .layout-panel .layouts .layout img {
        width: 50px; }
      .layout-panel .layouts .layout:nth-last-child(1) {
        padding-right: 1rem; }

@media (min-width: 576px) {
  .pages .page-container {
    width: 300px;
    height: 450px; } }

@media (min-width: 992px) {
  .pages .page-container {
    width: 400px;
    height: 600px; } }

.StoryPlayer_prev-btn__3S9mZ, .StoryPlayer_next-btn__7ldBW {
  border: none;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  cursor: pointer; }
  .StoryPlayer_prev-btn__3S9mZ:hover, .StoryPlayer_next-btn__7ldBW:hover, .StoryPlayer_prev-btn__3S9mZ:focus, .StoryPlayer_next-btn__7ldBW:focus {
    outline: none; }

.StoryPlayer_prev-btn__3S9mZ, .StoryPlayer_next-btn__7ldBW {
  width: 34px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 11;
  display: block;
  padding: 5px; }
  .StoryPlayer_prev-btn__3S9mZ svg, .StoryPlayer_next-btn__7ldBW svg {
    width: 26px;
    height: 26px;
    pointer-events: none;
    fill: var(--dark); }

.StoryPlayer_prev-btn__3S9mZ {
  left: -34px; }

.StoryPlayer_next-btn__7ldBW {
  right: -34px; }

.StoryPlayer_container__3GpFL {
  height: 100%;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  position: relative;
  touch-action: none; }

.StoryPlayer_player__1zTVW {
  width: auto;
  margin: 0 auto;
  position: relative;
  touch-action: none; }

.StoryPlayer_two-pages__1XeVn {
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100%;
  margin: 0 auto;
  touch-action: none; }

.StoryPlayer_page__1Ae8k {
  color: #000000;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  height: 100%;
  min-height: 100%;
  text-align: center;
  touch-action: none;
  box-sizing: content-box;
  border-right: none; }

.StoryPlayer_bottom-panel__dz_Xa {
  background-color: #000000;
  color: #ffffff;
  height: 40px;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.StoryPlayer_vertical-rhythm__nirfz {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .StoryPlayer_vertical-rhythm__nirfz div {
    padding: 5px 10px; }

@media (min-width: 576px) {
  .StoryPlayer_prev-btn__3S9mZ {
    display: block;
    left: -34px; }
  .StoryPlayer_next-btn__7ldBW {
    display: block;
    right: -34px; }
  .StoryPlayer_container__3GpFL {
    height: 600px;
    min-height: 600px; } }

.StoryPreview_pages__2Toim {
  position: absolute;
  top: 40px;
  width: 100%;
  height: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  margin-top: unset; }

@media (min-width: 576px) {
  .StoryPreview_pages__2Toim {
    position: relative;
    top: 0;
    margin-top: 25px; } }

.tip-editor.quill .ql-container {
  font-size: 17px;
  line-height: 1.6;
  letter-spacing: -0.004em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  /*
  @media (min-width: $screen-sm-min) {
    font-size: $font-size-editor-desktop;
    line-height: $line-height-editor-desktop;
    letter-spacing: $letter-spacing-editor-desktop;

    ol,
    ul {
      padding-left: 2em;
    }

    blockquote {
      font-size: $font-size-editor-desktop + 1px;
      margin-bottom: $margin-bottom-editor-p-desktop;
    }

    p,
    ul {
      margin-bottom: $margin-bottom-editor-p-desktop;
    }

    h1 {
      font-size: $font-size-editor-h1-desktop;
    }
    h3 {
      font-size: $font-size-editor-h3-desktop;
    }
    h4 {
      font-size: $font-size-editor-h4-desktop;
    }
  }
  */ }
  .tip-editor.quill .ql-container h1,
  .tip-editor.quill .ql-container .h1,
  .tip-editor.quill .ql-container h2,
  .tip-editor.quill .ql-container .h2,
  .tip-editor.quill .ql-container h3,
  .tip-editor.quill .ql-container .h3 {
    margin-top: 0;
    margin-bottom: 30px; }
  .tip-editor.quill .ql-container h1,
  .tip-editor.quill .ql-container h2,
  .tip-editor.quill .ql-container h3,
  .tip-editor.quill .ql-container h4,
  .tip-editor.quill .ql-container h5,
  .tip-editor.quill .ql-container h6,
  .tip-editor.quill .ql-container .h1,
  .tip-editor.quill .ql-container .h2,
  .tip-editor.quill .ql-container .h3,
  .tip-editor.quill .ql-container .h4,
  .tip-editor.quill .ql-container .h5,
  .tip-editor.quill .ql-container .h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit; }
  .tip-editor.quill .ql-container ol,
  .tip-editor.quill .ql-container ul {
    padding-left: 1.5em; }
    .tip-editor.quill .ql-container ol li,
    .tip-editor.quill .ql-container ul li {
      padding-left: 0.2em;
      text-align: left; }
  .tip-editor.quill .ql-container blockquote {
    font-size: 18px;
    margin-bottom: 19px;
    padding: 12.5px 25px;
    border-left: 5px solid #eeeeee; }
  .tip-editor.quill .ql-container p,
  .tip-editor.quill .ql-container ul,
  .tip-editor.quill .ql-container .responsive-video {
    margin-bottom: 20px; }
  .tip-editor.quill .ql-container h1 {
    font-size: 33px; }
  
  .tip-editor.quill .ql-container h3 {
    font-size: 30px; }
  
  .tip-editor.quill .ql-container h4 {
    font-size: 24px; }
  .tip-editor.quill .ql-container .ql-font-opensans {
    font-family: 'Open Sans', sans-serif; }
  .tip-editor.quill .ql-container .ql-font-roboto {
    font-family: 'Roboto', sans-serif; }
  .tip-editor.quill .ql-container .ql-font-raleway {
    font-family: 'Raleway', sans-serif; }
  .tip-editor.quill .ql-container img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block; }
  .tip-editor.quill .ql-container a {
    color: #06c !important; }
    .tip-editor.quill .ql-container a:hover, .tip-editor.quill .ql-container a:focus {
      color: #0056b3 !important; }

.tip-editor.quill {
  padding: 10px; }
  .tip-editor.quill .ql-container .ql-video {
    margin-bottom: 20px; }
  .tip-editor.quill .ql-container img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block; }
  .tip-editor.quill:focus {
    outline: 0 !important;
    -webkit-appearance: none; }
  .tip-editor.quill::after {
    font-style: normal; }
  .tip-editor.quill .ql-toolbar .ql-header {
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    color: #ccc; }
    .tip-editor.quill .ql-toolbar .ql-header.ql-active, .tip-editor.quill .ql-toolbar .ql-header:hover, .tip-editor.quill .ql-toolbar .ql-header:focus {
      color: white; }
  .tip-editor.quill .ql-editor {
    padding: 0;
    line-height: inherit; }
    .tip-editor.quill .ql-editor :last-child {
      margin-bottom: 0; }
  .tip-editor.quill input.ql-image[type=file] {
    display: none; }
  .tip-editor.quill .ql-snow .ql-picker.ql-color .ql-picker-item:nth-child(1) {
    border: 1px solid lightblue; }
  .tip-editor.quill .ql-toolbar.ql-snow .ql-size.ql-picker.ql-expanded .ql-picker-options {
    top: -535px; }
  .tip-editor.quill .ql-snow .ql-picker.ql-size {
    width: 60px; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label::before {
      content: '(d)'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before {
      content: '-5'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="13px"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="13px"]::before {
      content: '-4'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before {
      content: '-3'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="15px"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="15px"]::before {
      content: '-2'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before {
      content: '-1'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="17px"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="17px"]::before {
      content: '0'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before {
      content: '+1'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="19px"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="19px"]::before {
      content: '+2'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before {
      content: '+3'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="21px"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="21px"]::before {
      content: '+4'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before {
      content: '+5'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="23px"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="23px"]::before {
      content: '+6'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before {
      content: '+7'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="25px"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="25px"]::before {
      content: '+8'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before {
      content: '+9'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="27px"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="27px"]::before {
      content: '+10'; }
  .tip-editor.quill .ql-toolbar.ql-snow .ql-font.ql-picker.ql-expanded .ql-picker-options {
    top: -132px; }
  .tip-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-item::before, .tip-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-label::before {
    content: '(default)'; }
  .tip-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="opensans"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="opensans"]::before {
    content: 'Open Sans'; }
  .tip-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="roboto"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="roboto"]::before {
    content: 'Roboto'; }
  .tip-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="raleway"]::before, .tip-editor.quill .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="raleway"]::before {
    content: 'Raleway'; }
  .tip-editor.quill .ql-toolbar.ql-snow .ql-lineheight.ql-picker.ql-expanded .ql-picker-options {
    top: -380px; }
  .tip-editor.quill .ql-snow .ql-picker.ql-lineheight {
    width: 60px; }
    .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item::before, .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label::before {
      content: '(d)'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1']::before, .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1']::before {
      content: '1.0'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.2']::before, .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.2']::before {
      content: '1.2'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.5']::before, .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.5']::before {
      content: '1.5'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.6']::before, .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.6']::before {
      content: '1.6'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.8']::before, .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.8']::before {
      content: '1.8'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2']::before, .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2']::before {
      content: '2.0'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.4']::before, .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.4']::before {
      content: '2.4'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.8']::before, .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.8']::before {
      content: '2.8'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='3']::before, .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='3']::before {
      content: '3.0'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='4']::before, .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='4']::before {
      content: '4.0'; }
    .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='5']::before, .tip-editor.quill .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='5']::before {
      content: '5.0'; }
  .tip-editor.quill .ql-toolbar.ql-snow .ql-size.ql-picker,
  .tip-editor.quill .ql-toolbar.ql-snow .ql-font.ql-picker,
  .tip-editor.quill .ql-toolbar.ql-snow .ql-line-height.ql-picker {
    margin-bottom: 8px; }
  .tip-editor.quill .ql-toolbar.ql-snow .ql-header {
    clear: left; }
  .tip-editor.quill .ql-snow .ql-picker-label {
    color: white; }
    .tip-editor.quill .ql-snow .ql-picker-label:hover, .tip-editor.quill .ql-snow .ql-picker-label:active {
      color: white; }

.tip-editor.quill {
  margin-bottom: 2em;
  padding-bottom: 2.5em;
  border-bottom: none !important; }
  .tip-editor.quill .ql-container .ql-editor {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    line-height: 1.6;
    color: #000000; }

.top-menu {
  background-color: #f8f8f8; }
  .top-menu button,
  .top-menu a,
  .top-menu span {
    padding: 8px 14px;
    text-decoration: none;
    color: buttontext; }
    .top-menu button:hover, .top-menu button:focus,
    .top-menu a:hover,
    .top-menu a:focus,
    .top-menu span:hover,
    .top-menu span:focus {
      outline: none;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      text-decoration: none;
      color: buttontext; }
    .top-menu button.active,
    .top-menu a.active,
    .top-menu span.active {
      color: var(--primary); }
  .top-menu .separator {
    width: 2px; }
    .top-menu .separator .bg {
      background-color: #ddd;
      height: 20px; }

.top-menu button,
.top-menu a,
.top-menu span, .delete-btn {
  border: none;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  cursor: pointer; }
  .top-menu button:hover,
  .top-menu a:hover,
  .top-menu span:hover, .delete-btn:hover, .top-menu button:focus,
  .top-menu a:focus,
  .top-menu span:focus, .delete-btn:focus {
    outline: none; }

.delete-btn {
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--white);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .delete-btn svg {
    margin: 0 auto;
    pointer-events: none;
    width: 16px;
    height: 16px;
    stroke: var(--white);
    fill: var(--white); }

.delete-btn:hover, .delete-btn:active {
  text-decoration: none !important; }

.bottom-bar .add-button svg {
  pointer-events: none;
  width: 30px;
  height: 30px;
  fill: var(--gray); }

.tips.container-fluid {
  padding-right: 10px;
  padding-left: 10px; }

.tips .row {
  margin-left: -5px;
  margin-right: -5px; }

.tips .col-sm {
  padding-left: 5px;
  padding-right: 5px; }

.tips .tip:focus, .tips .tip:hover {
  text-decoration: none;
  color: #636b6f; }

.SimpleImageUploadControl_top-button__31arB {
  border: none;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  cursor: pointer; }
  .SimpleImageUploadControl_top-button__31arB:hover, .SimpleImageUploadControl_top-button__31arB:focus {
    outline: none; }

.SimpleImageUploadControl_image-upload-control__EpD7S {
  border: 1px solid #cccccc;
  padding: 1rem 0; }

.SimpleImageUploadControl_buttons__SUGoV {
  display: flex;
  flex-direction: row;
  justify-content: center; }

.SimpleImageUploadControl_top-button__31arB {
  margin: 1rem; }
  .SimpleImageUploadControl_top-button__31arB svg {
    pointer-events: none;
    width: 44px;
    height: 44px;
    fill: var(--gray); }
  .SimpleImageUploadControl_top-button__31arB:hover {
    outline: none; }

.SimpleImageUploadControl_image-container__3Vrs3 {
  position: relative;
  touch-action: none; }
  .SimpleImageUploadControl_image-container__3Vrs3 .SimpleImageUploadControl_edit-button__3wvDK {
    text-align: center;
    width: 100%;
    position: absolute;
    top: 43%;
    top: calc(50% - 30px - 6px);
    left: 0;
    cursor: -webkit-grab;
    cursor: grab; }
    .SimpleImageUploadControl_image-container__3Vrs3 .SimpleImageUploadControl_edit-button__3wvDK svg {
      pointer-events: none;
      width: 60px;
      height: 60px;
      fill: rgba(150, 150, 150, 0.6); }
  .SimpleImageUploadControl_image-container__3Vrs3 .SimpleImageUploadControl_my-spinner__2h2bY {
    text-align: center;
    position: absolute;
    top: 45%;
    top: calc(50% - 16px - 6px);
    left: 45%;
    left: calc(50% - 16px - 6px);
    margin: 0 auto; }

.frontpage-preview {
  background-color: #ffffff; }
  .frontpage-preview .section-hero,
  .frontpage-preview .section-diario {
    position: relative;
    background: none;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    color: #ffffff;
    margin-bottom: 10px;
    height: 510px; }
    .frontpage-preview .section-hero .content,
    .frontpage-preview .section-diario .content {
      z-index: 3; }
    .frontpage-preview .section-hero .cta-subtitle,
    .frontpage-preview .section-diario .cta-subtitle {
      font-family: "Foco", serif;
      font-size: 14px;
      letter-spacing: 0.2em; }
    .frontpage-preview .section-hero .cta-title,
    .frontpage-preview .section-diario .cta-title {
      font-family: "Foco Bold", serif;
      font-size: 60px;
      letter-spacing: 0.1em;
      line-height: 60px;
      margin-top: 15px; }
    .frontpage-preview .section-hero .cta-btn,
    .frontpage-preview .section-diario .cta-btn {
      font-family: "Foco Bold", serif;
      font-size: 14px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      color: #2c2c2c;
      width: auto;
      background-color: rgba(255, 255, 255, 0.6);
      border: none;
      padding: 12px 40px;
      margin-top: 40px;
      border-radius: 2px; }
      .frontpage-preview .section-hero .cta-btn svg,
      .frontpage-preview .section-diario .cta-btn svg {
        fill: #2c2c2c; }
      .frontpage-preview .section-hero .cta-btn:hover, .frontpage-preview .section-hero .cta-btn:active, .frontpage-preview .section-hero .cta-btn:focus,
      .frontpage-preview .section-diario .cta-btn:hover,
      .frontpage-preview .section-diario .cta-btn:active,
      .frontpage-preview .section-diario .cta-btn:focus {
        box-shadow: none;
        background-color: rgba(230, 230, 230, 0.6) !important;
        color: #2c2c2c !important; }
      .frontpage-preview .section-hero .cta-btn .label,
      .frontpage-preview .section-diario .cta-btn .label {
        line-height: 24px;
        display: inline-block;
        padding-left: 10px;
        vertical-align: middle; }
    .frontpage-preview .section-hero .video-background,
    .frontpage-preview .section-diario .video-background {
      background-color: #000000;
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      overflow: hidden;
      pointer-events: none; }
      .frontpage-preview .section-hero .video-background iframe,
      .frontpage-preview .section-diario .video-background iframe {
        display: none;
        border-width: 0;
        position: absolute;
        top: -40%;
        left: -30%;
        min-width: 170%;
        min-height: 170%;
        opacity: 0;
        z-index: 1;
        pointer-events: none;
        -webkit-transition: opacity 0.3s ease-in;
        transition: opacity 0.3s ease-in; }
        .frontpage-preview .section-hero .video-background iframe.loaded,
        .frontpage-preview .section-diario .video-background iframe.loaded {
          opacity: 1; }
    .frontpage-preview .section-hero .tint,
    .frontpage-preview .section-diario .tint {
      width: 100%;
      height: 100%;
      background-color: #000000;
      position: absolute;
      z-index: 2; }
  .frontpage-preview .section-diario {
    margin-top: 10px;
    margin-bottom: 10px; }
  .frontpage-preview .section-categories .title {
    font-family: "EpicRide", sans-serif;
    white-space: nowrap;
    font-size: 52px;
    line-height: 52px;
    color: #d3b6a0;
    display: inline-block;
    padding-left: 12px;
    padding-right: 12px;
    position: relative; }
    .frontpage-preview .section-categories .title .heart-icon {
      position: absolute;
      top: 40%;
      right: 0;
      background-image: url(/img/index/icon-heart.png);
      background-repeat: no-repeat;
      background-position: right center;
      background-size: contain;
      width: 8.5px;
      height: 7px; }
  .frontpage-preview .section-categories .text-box {
    font-family: 'Foco', serif;
    font-size: 18px;
    max-width: 540px;
    margin-bottom: 1.7rem; }
  .frontpage-preview .section-categories .categories .box {
    z-index: 1;
    display: block;
    width: 370px;
    height: 520px;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative; }
    .frontpage-preview .section-categories .categories .box .caption {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      background-color: rgba(0, 0, 0, 0);
      color: white; }
      .frontpage-preview .section-categories .categories .box .caption .sub {
        font-family: "EpicRide", sans-serif;
        font-size: 72px;
        line-height: 72px; }
      .frontpage-preview .section-categories .categories .box .caption .main {
        font-family: "Foco", serif;
        font-size: 18px;
        letter-spacing: 0.2em;
        margin-top: 4px; }
  .frontpage-preview .section-statistics {
    background-color: #f7f2ee; }
    .frontpage-preview .section-statistics .stat {
      max-width: 1200px;
      width: 100%; }
      .frontpage-preview .section-statistics .stat .number {
        color: #d3b6a0;
        font-family: "EpicRide", sans-serif;
        font-size: 60px;
        min-width: 50px; }
      .frontpage-preview .section-statistics .stat .vertical-separator {
        width: 2px;
        margin-right: 16px; }
        .frontpage-preview .section-statistics .stat .vertical-separator .bg {
          background-color: #eddfd4;
          width: 100%;
          height: 60px; }
      .frontpage-preview .section-statistics .stat .label {
        font-family: "Foco", serif;
        letter-spacing: 0.2em;
        font-size: 18px;
        text-transform: uppercase;
        text-align: left;
        color: #878787; }

@media (min-width: 360px) {
  .frontpage-preview .section-categories .title {
    font-size: 64px;
    line-height: 64px;
    padding-left: 16px;
    padding-right: 16px; }
    .frontpage-preview .section-categories .title .heart-icon {
      top: 40%;
      width: 13px;
      height: 11px; } }

@media (min-width: 576px) {
  .frontpage-preview .section-hero,
  .frontpage-preview .section-diario {
    height: 700px; }
    .frontpage-preview .section-hero .video-background,
    .frontpage-preview .section-diario .video-background {
      display: block; }
      .frontpage-preview .section-hero .video-background iframe,
      .frontpage-preview .section-diario .video-background iframe {
        display: block; }
  .frontpage-preview .section-categories .title {
    font-size: 110px;
    line-height: 110px;
    padding-left: 20px;
    padding-right: 20px; }
    .frontpage-preview .section-categories .title .heart-icon {
      top: 40%;
      width: 17px;
      height: 14px; } }

html,
body,
#root {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif; }

#root {
  background-color: #e5e5e5; }

.normal-container {
  max-width: 700px;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }

.compact-container {
  max-width: 340px;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }

.full-height {
  height: 100vh; }

.bottom-bar {
  background-color: #f8f8f8;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: var(--white);
  text-align: center;
  padding: 0.5rem;
  z-index: 4; }
  .bottom-bar.buttonless button {
    border: none;
    background-color: transparent;
    outline: none;
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
    width: 100%; }
  .bottom-bar a {
    color: var(--dark);
    width: 100%;
    display: block;
    text-decoration: none; }

fieldset {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px; }
  fieldset legend {
    padding: 0 5px;
    font-size: 18px;
    margin-bottom: 4px;
    width: auto;
    color: #bbb; }

form {
  background-color: var(--white); }

.my-toast {
  min-height: unset !important; }
  .my-toast .my-toast-body {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 4px; }

