@import "../variables";
@import "../mixins";

.pages {
    margin-left:auto;
    margin-right:auto;
    background-color: #e5e5e5;
    .page-container {
        margin: 15px;
        display:flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: $item-xs-width;
        height: $item-xs-height;
        background-color: var(--white);
        transition: border-width 0.2s ease-in-out;
        &.swiper-slide-active {
            border-width: 0;
        }
        .page {
            box-shadow: $card-box-shadow;
        }
    }
}
.layout-panel {
    background-color: var(--dark);
    color: var(--light);
    position: fixed;
    bottom:0;
    left:0;
    width: 100%;
    padding:1rem;
    display: none;
    z-index:5;

    &.show {
        display: block;
    }
    .close-button {
        background-color: var(--gray);
        border-radius: 50%;
        position: absolute;
        top:-15px;
        right:calc(50% - 15px);
        width: 34px;
        height: 34px;
        line-height: 26px;
        text-align: center;
        z-index:3;
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex-direction: column;
        svg {
            margin:0 auto;
            pointer-events: none;
            width: 16px;
            height: 16px;
            stroke: var(--white);
            fill: var(--white);
        }
    }
    .layouts {
        overflow-x: auto;
        border: 1px solid var(--light);
        box-sizing: border-box;
        border-radius: 4px;
        .layout {
            @extend %neutralize-button;
            margin:0;
            img {
                width:50px;
            }
            &:nth-last-child(1) {
                padding-right: 1rem;
            }
        }
    }
}

@media (min-width: $breakpoint-sm) {
    .pages {
        .page-container {
            width: $item-sm-width;
            height: $item-sm-height;
        }
    }
}

@media (min-width: $breakpoint-lg) {
    .pages {
        .page-container {
            width: $item-lg-width;
            height: $item-lg-height;
        }
    }
}
