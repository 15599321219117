%editor-typography {
  font-size: $font-size-editor-mobile;
  line-height: $line-height-editor-mobile;
  letter-spacing: $letter-spacing-editor-mobile;
  font-family: $font-family-sans-serif;

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3 {
    //margin-top: 25px;
    //margin-bottom: 12.5px;

    margin-top: 0;
    margin-bottom: 30px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
  }

  ol,
  ul {
    padding-left: 1.5em;

    li {
      padding-left: 0.2em;
      text-align: left;
    }
  }

  blockquote {
    font-size: $font-size-editor-mobile + 1px;
    margin-bottom: $margin-bottom-editor-p-mobile - 1px;
    padding: 12.5px 25px;
    border-left: 5px solid #eeeeee;
  }

  p,
  ul,
  .responsive-video {
    margin-bottom: $margin-bottom-editor-p-mobile;
  }

  h1 {
    font-size: $font-size-editor-h1-mobile;
  }
  h3 {
    font-size: $font-size-editor-h3-mobile;
  }
  h4 {
    font-size: $font-size-editor-h4-mobile;
  }

  .ql-font-opensans { font-family: 'Open Sans', sans-serif; }
  .ql-font-roboto { font-family: 'Roboto', sans-serif; }
  .ql-font-raleway { font-family: 'Raleway', sans-serif; }
  // .ql-font-default {
      // font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  // }

  img {
    max-width: 100%;
    height: auto;
    margin:0 auto;
    display: block;
  }

  a {
    color: #06c !important;
    &:hover, &:focus {
      color: #0056b3 !important;
    }
  }

  /*
  @media (min-width: $screen-sm-min) {
    font-size: $font-size-editor-desktop;
    line-height: $line-height-editor-desktop;
    letter-spacing: $letter-spacing-editor-desktop;

    ol,
    ul {
      padding-left: 2em;
    }

    blockquote {
      font-size: $font-size-editor-desktop + 1px;
      margin-bottom: $margin-bottom-editor-p-desktop;
    }

    p,
    ul {
      margin-bottom: $margin-bottom-editor-p-desktop;
    }

    h1 {
      font-size: $font-size-editor-h1-desktop;
    }
    h3 {
      font-size: $font-size-editor-h3-desktop;
    }
    h4 {
      font-size: $font-size-editor-h4-desktop;
    }
  }
  */
}

%editor {
  padding: 10px;

  .ql-container {
    @extend %editor-typography;

    .ql-video {
      margin-bottom:20px;
    }
    
    img {
      max-width: 100%;
      height: auto;
      margin:0 auto;
      display: block;
    }
  }

  &:focus {
    outline: 0 !important;
    -webkit-appearance: none;
  }
  &::after {
    font-style: normal;
  }

  .ql-toolbar {
    .ql-header {
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
      color: #ccc;
      &.ql-active,
      &:hover,
      &:focus {
        color: white;
      }
    }
  }

  .ql-editor {
    padding: 0;
    line-height: inherit;
    //p, ol, ul, pre, blockquote,
    //h1, h2, h3, h4, h5, h6 {
    //  padding: inherit;
    //  margin: inherit;
    //}
    :last-child {
      margin-bottom: 0;
    }
  }

  input.ql-image[type=file] {
    display:none;
  }

  .ql-snow .ql-picker.ql-color  {
    .ql-picker-item:nth-child(1) {
        border: 1px solid lightblue;        
    }
  }

  // Font size selector (through font-size style property).
  .ql-toolbar.ql-snow .ql-size.ql-picker.ql-expanded .ql-picker-options {
      top: -535px;
  }

  .ql-snow .ql-picker.ql-size {
      width: 60px;
      .ql-picker-item, .ql-picker-label {
          &::before { content: '(d)'; }
          &[data-value="12px"]::before { content: '-5'; }
          &[data-value="13px"]::before { content: '-4'; }
          &[data-value="14px"]::before { content: '-3'; }
          &[data-value="15px"]::before { content: '-2'; }
          &[data-value="16px"]::before { content: '-1'; }
          &[data-value="17px"]::before { content: '0'; }
          &[data-value="18px"]::before { content: '+1'; }
          &[data-value="19px"]::before { content: '+2'; }
          &[data-value="20px"]::before { content: '+3'; }
          &[data-value="21px"]::before { content: '+4'; }
          &[data-value="22px"]::before { content: '+5'; }
          &[data-value="23px"]::before { content: '+6'; }
          &[data-value="24px"]::before { content: '+7'; }
          &[data-value="25px"]::before { content: '+8'; }
          &[data-value="26px"]::before { content: '+9'; }
          &[data-value="27px"]::before { content: '+10'; }
      }
  }

  // Font selector (through classes like .ql-font-opensans).
  .ql-toolbar.ql-snow .ql-font.ql-picker.ql-expanded .ql-picker-options {
      top: -132px;
  }
  .ql-snow .ql-picker.ql-font  {
      .ql-picker-item, .ql-picker-label {
          &::before { content: '(default)'; }
          // &[data-value="default"]::before { content: '(default)'; }
          &[data-value="opensans"]::before { content: 'Open Sans'; }
          &[data-value="roboto"]::before { content: 'Roboto'; }
          &[data-value="raleway"]::before { content: 'Raleway'; }
      }
  }

  // @see %editor-typography for classes .ql-font-roboto, etc.

  // Line-height selector (through line-height style property).
  .ql-toolbar.ql-snow .ql-lineheight.ql-picker.ql-expanded .ql-picker-options {
      top: -380px;
  }

  .ql-snow .ql-picker.ql-lineheight  {
      width: 60px;
      .ql-picker-item, .ql-picker-label {
          // Please note the integer data-value-s should be without float point. 
          &::before {content: '(d)';}
          &[data-value='1']::before {content: '1.0';}
          &[data-value='1.2']::before {content: '1.2';}
          &[data-value='1.5']::before {content: '1.5';}
          &[data-value='1.6']::before {content: '1.6';}
          &[data-value='1.8']::before {content: '1.8';}
          &[data-value='2']::before {content: '2.0';}
          &[data-value='2.4']::before {content: '2.4';}
          &[data-value='2.8']::before {content: '2.8';}
          &[data-value='3']::before {content: '3.0';}
          &[data-value='4']::before {content: '4.0';}
          &[data-value='5']::before {content: '5.0';}
      }
  }

  // Making the toolbar split to two lines.
  .ql-toolbar.ql-snow .ql-size.ql-picker,
  .ql-toolbar.ql-snow .ql-font.ql-picker,
  .ql-toolbar.ql-snow .ql-line-height.ql-picker {
      margin-bottom: 8px;
  }
  .ql-toolbar.ql-snow .ql-header {
      clear: left;
  }

  .ql-snow .ql-picker-label {
      color: white;
      &:hover, &:active {
          color: white;
      }
  }
}
