html,
body,
#root {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  background-color: #e5e5e5;
}

.normal-container {
  max-width:700px;
  width:100%;
  margin-left:auto;
  margin-right:auto;
}
.compact-container {
  max-width:340px;
  width:100%;
  margin-left:auto;
  margin-right:auto;
}

.full-height {
  height: 100vh;
}

.bottom-bar {
  background-color: #f8f8f8;
  position: fixed;
  bottom:0;
  left:0;
  width: 100%;
  color: var(--white);
  text-align: center;
  padding:0.5rem;
  z-index:4;

  &.buttonless button {
    border:none;
    background-color: transparent;
    outline: none;
    appearance: none !important;
    width: 100%;
  }
  a {
    color: var(--dark);
    width: 100%;
    display: block;
    text-decoration: none;
  }
}

fieldset {
  padding:15px;
  border:1px solid #ddd;
  border-radius: 5px;
  legend {
    padding: 0 5px;
    font-size: 18px;
    margin-bottom:4px;
    width:auto;
    color:#bbb;
  }
}

form {
  background-color: var(--white);
}

.my-toast {
  min-height: unset !important;
  .my-toast-body {
      margin-top:4px;
      margin-bottom:4px;
      margin-left: 4px;
  }
  .my-toast-progress-bar {
      // display: none; // causes that a toast is not automatically hidden.
  }
}
