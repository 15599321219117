.cover-page-layout-selector {
    .cover-layout {
        input[type="radio"] {
            display: none;
        }
        label {
            cursor: pointer;
            font-size: 14px;
            border: 1px solid transparent;
        }
        input[type="radio"]:checked+label {
            background-color: var(--primary);
            border: 1px solid var(--primary);
            color: var(--white);
        }
    }
}
