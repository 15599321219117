.layout {
    height: 100%;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    background-color: #e5e5e5;

    &.light {
        background-color: var(--white);
    }
}
