// Misc, for example story pages, etc.
@import url('https://fonts.googleapis.com/css?family=Lato|Rock+Salt|Open+Sans:300|Prata|Libre+Baskerville:400i|Montserrat:200,400|Lora|Roboto+Condensed:300|Roboto+Slab:300|Playfair+Display:400,400i|Raleway:500,700');

// Frontpage.
@import url('https://fonts.googleapis.com/css?family=Lora&display=swap');

// Original TTF was converted to Woff(2) through transfonter.org.
@font-face {
  font-family: 'EpicRide';
  font-style: normal;
  font-weight: normal;
  src: local('EpicRide'), 
        url('/fonts/EpicRide/EpicRide.woff2') format('woff2'),
        url('/fonts/EpicRide/EpicRide.woff') format('woff'),
        url('/fonts/EpicRide/EpicRide.ttf') format('ttf');
}

@font-face {
  font-family: 'Foco';
  font-style: normal;
  font-weight: normal;
  src: local('Foco'), url('/fonts/Foco/FOCO.woff') format('woff');
}

@font-face {
  font-family: 'Foco Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Foco Bold'), url('/fonts/Foco/FOCOBOLD.woff') format('woff');
}
