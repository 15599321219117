@import "../../variables";
@import "../../mixins";

.frontpage-preview {
  background-color: #ffffff;

  .section-hero,
  .section-diario {
    position: relative;
    background:none;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    color: #ffffff;
    margin-bottom: 10px;
    height: 510px;
    .content {
      z-index: 3;
    }
    .cta-subtitle {
        font-family: $lvdp-font-foco;
        font-size: 14px;
        letter-spacing: 0.2em;
    }
    .cta-title {
        font-family: $lvdp-font-foco-bold;
        font-size: 60px;
        letter-spacing: 0.1em;
        line-height: 60px;
        margin-top: 15px;
    }
    .cta-btn {
        @include frontpage-cta-button(#2c2c2c, rgba(255, 255, 255, 0.6));
    }
    .video-background {
      background-color: #000000;
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      overflow: hidden;
      pointer-events: none;
      iframe {
        display: none;
        border-width: 0;
        position: absolute;
        top:-40%;
        left:-30%;
        min-width: 170%;
        min-height: 170%;
        opacity: 0;
        z-index: 1;
        pointer-events: none;

        @include transition(opacity 0.3s ease-in);

        &.loaded {
          opacity: 1;
        }
      }
    }
    .tint {
      width:100%;
      height:100%;
      background-color: #000000;
      position: absolute;
      z-index:2;
    }
  }

  .section-diario {
    margin-top:10px;
    margin-bottom:10px;
  }

  .section-categories {
    .title {
        font-family: $lvdp-font-epicride;
        white-space: nowrap;
        font-size: 52px;
        line-height: 52px;
        color: $lvdp-frontpage-title-color;
        display: inline-block;
        padding-left: 12px;
        padding-right: 12px;
        position: relative;
        .heart-icon {
          position: absolute;
          top: 40%;
          right: 0;
          background-image: url(/img/index/icon-heart.png);
          background-repeat: no-repeat;
          background-position: right center;
          background-size: contain;
          width: 8.5px; // 17/2
          height: 7px; // 14/2
        }
    }
    .text-box {
        font-family: 'Foco', serif;
        font-size: 18px;
        max-width: 540px;
        margin-bottom: 1.7rem;
    }

    .categories {
      .box {
        z-index:1;
        display: block;
        width: 370px;
        height: 520px;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        .caption {
          position: absolute;
          bottom:0;
          width: 100%;
          text-align: center;
          background-color: rgba(#000000, 0);
          color: white;
          .sub {
            font-family: $lvdp-font-epicride;
            font-size: 72px;
            line-height: 72px;
          }
          .main {
            font-family: $lvdp-font-foco;
            font-size: 18px;
            letter-spacing: 0.2em;
            margin-top:4px;
          } 
        }
      }
      .box1 {
          // background-image: url(/img/index/section-categories/find.png);
      }
      .box2 {
          // background-image: url(/img/index/section-categories/tips.png);
      }
      .box3 {
          // background-image: url(/img/index/section-categories/experiences.png);
      }
    }
  }

  .section-statistics {
    background-color:$lvdp-frontpage-highlighted-section-bg-color;
    .stat {
      max-width: 1200px;
      width: 100%;
      .number {
          color: $lvdp-frontpage-title-color;
          font-family: $lvdp-font-epicride;
          font-size: 60px;
          min-width: 50px;
      }
      .vertical-separator {
          width: 2px;
          margin-right:16px;
          .bg {
              background-color: $lvdp-frontpage-separator-color;
              width: 100%;
              height: 60px;
          }
      }
      .label { 
          font-family: $lvdp-font-foco;
          letter-spacing: 0.2em;
          font-size: 18px;
          text-transform: uppercase;
          // min-width: 180px;
          text-align: left;
          color: $lvdp-frontpage-subtitle-text-color;
      }
    }
  }
}

@media (min-width: 360px) {
  .frontpage-preview {
    .section-categories {
      .title {
        font-size: 64px;
        line-height: 64px;
        padding-left:16px;
        padding-right:16px;
        .heart-icon {
            top: 40%;
            width: 13px; // 17 * (3/4)
            height: 11px; // 14 * (3/4)
        }
      }
    }
  }
}

@media (min-width: $breakpoint-sm) {
  .frontpage-preview {
    .section-hero,
    .section-diario {
      height:700px;
      .video-background {
        display: block;
        iframe {
          display: block;
        }
      }
    }

    .section-categories {
      .title {
        font-size: $lvdp-frontpage-title-font-size;
        line-height: $lvdp-frontpage-title-font-size;
        padding-left:20px;
        padding-right: 20px;
        .heart-icon {
            top: 40%;
            width: 17px;
            height: 14px;
        }
      }
    }
  }
}
